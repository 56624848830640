import { Link, useNavigate } from "react-router-dom";
import GradientButton from "../../Common/Buttons/GradientButton";
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { useUser } from "../../../utils/contexts/userContext";
import { useState, useEffect } from "react";
import {
  createFollowRequest,
  unfollow,
  getFollowers,
} from "../../../utils/fetchs/followRequestFetchs";

const api_url = getImgUrl();

export function SuggestionCard({ profileUser }) {
  const { loggedInUser } = useUser();
  const isLoggedIn = loggedInUser && loggedInUser.email;
  const navigate = useNavigate();
  const [isFollowingUser, setIsFollowingUser] = useState(false);

  // Fetch initial follow status
  useEffect(() => {
    const checkIfFollowing = async () => {
      if (isLoggedIn) {
        const followers = await getFollowers(loggedInUser, profileUser._id);
        setIsFollowingUser(
          followers.some((follower) => follower._id === loggedInUser._id)
        );
      }
    };

    checkIfFollowing();
  }, [profileUser, loggedInUser]);

  const handleFollow = async (user) => {
    if (isFollowingUser) {
      // Unfollow the user if already following
      const followExist = await unfollow(loggedInUser, user._id);
      if (followExist) {
        setIsFollowingUser(false);
      }
    } else {
      // Follow the user if not already following
      const response = await createFollowRequest(loggedInUser, user._id);
      if (response) {
        setIsFollowingUser(true);
      }
    }
  };

  const handleButtonClick = () => {
    if (isLoggedIn) {
      handleFollow(profileUser);
    } else {
      navigate("/login-register");
    }
  };

  return (
    <div className="suggestion">
      <Link to={`/${profileUser.username}`} className="user">
        <div className="profile_picture">
          <img
            src={`${api_url}/uploads/profilepics/${profileUser.profilepic}`}
            alt="profil"
          />
        </div>
        <p className="username">@{profileUser.username}</p>
      </Link>
      <GradientButton
        round={true}
        icon={isFollowingUser ? faCheck : faPlus}
        onClick={handleButtonClick}
      />
    </div>
  );
}
