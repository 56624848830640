import { useSpring } from "react-spring";

export const translateY100 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateY(100%)" },
    to: { opacity: 1, transform: "translateY(0%)" },
  });
};

export const translateYMinus100 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateY(-100%)" },
    to: { opacity: 1, transform: "translateY(0%)" },
  });
};

export const translateX10 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateX(10%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
  });
};

export const translateXMinus10 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateX(-10%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
  });
};

export const translateX100 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateX(100%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
  });
};

export const translateXMinus30 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateX(-30%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
  });
};

export const translateX30 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateX(30%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
  });
};

export const translateXMinus100 = () => {
  return useSpring({
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
  });
};

export const modalTransition = (modalIsOpen) => {
  return useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });
};

export const opacityTransition = () => {
  return useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
};
