import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

export async function markMessageAsRead(conversationId) {
  try {
    const response = await fetch(
      `${api_url}/message/mark-as-read/` + conversationId,
      {
        method: "PATCH",
      }
    );

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to mark message as read");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getMessagesByConversationId(conversationId) {
  try {
    const response = await fetch(`${api_url}/message/` + conversationId, {
      method: "GET",
    });
    switch (response.status) {
      case 200:
        return await response.json();
      case 404:
        return [];
      default:
        throw new Error("Unable to retreive message of conversation");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function sendMessage(msg, user) {
  try {
    const tk = await user.token;
    const response = await fetch(`${api_url}/message/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tk,
      },
      body: JSON.stringify({ message: msg, userId: user._id }),
    });

    switch (response.status) {
      case 201:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to create message");
    }
  } catch (error) {
    console.error(error.message);
  }
}
