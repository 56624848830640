import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/Loader/Loader";
import updateHead from "../../utils/helpers/updatePageHead";
import Feed from "../../components/Common/Feed/Feed";
import Header from "./tmpl/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faTicket,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import {
  getProfileFeed,
  getUserByUsername,
} from "../../utils/fetchs/userFetchs";
import { useUser } from "../../utils/contexts/userContext";
import { animated } from "react-spring";
import { translateXMinus100 } from "../../utils/animations/animations";

export default function Profile() {
  const { user } = useUser();
  const { username } = useParams();

  const title = `@${username} | Events and posts`;
  const cssPath = "/assets/css/pages/profile.css";

  const [profileUser, setProfileUser] = useState(null);
  const [selector, setSelector] = useState("");
  const [publications, setPublications] = useState([]);
  const [filteredPublications, setFilteredPublications] = useState([]);

  useEffect(() => {
    getUserByUsername(username).then((data) => setProfileUser(data));
  }, [username]);

  useEffect(() => {
    if (profileUser) {
      getProfileFeed(profileUser).then((publications) => {
        setPublications(publications);
        setSelector("creator_event");
      });
    }
  }, [profileUser]);

  useEffect(() => {
    if (profileUser) {
      switch (selector) {
        case "participant_futur_event":
          setFilteredPublications(
            publications.filter(
              (publication) =>
                publication.creator_id !== profileUser._id &&
                new Date(publication.publication_date).getTime() >= Date.now()
            )
          );
          break;
        case "participant_past_event":
          setFilteredPublications(
            publications.filter(
              (publication) =>
                publication.creator_id !== profileUser._id &&
                new Date(publication.publication_date).getTime() < Date.now()
            )
          );
          break;
        case "creator_event":
          setFilteredPublications(
            publications.filter(
              (publication) => publication.creator_id === profileUser._id
            )
          );
          break;
        default:
          break;
      }
    }
  }, [selector, profileUser]);
  const animation = translateXMinus100();

  if (!profileUser) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ title, cssPath })}
      <BackgroundGradientLur />
      <section>
        <Header profileUser={profileUser} loggedInUser={user} />
        <animated.div style={animation} className="profile_container">
          <div className="profile_icons">
            {/*TODO : Change icons for faCalendarRange, faCalendarStar, and faCalendarclock */}
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="icon"
              onClick={() => setSelector("participant_futur_event")}
            />
            <FontAwesomeIcon
              icon={faTicket}
              className="icon"
              onClick={() => setSelector("creator_event")}
            />
            <FontAwesomeIcon
              icon={faClockRotateLeft}
              className="icon"
              onClick={() => setSelector("participant_past_event")}
            />
          </div>
          {profileUser.isPrivate && profileUser._id !== user._id ? (
            <p>
              Cet utilisateur est privé, vous devez le suivre pour voir ses
              publications.
            </p>
          ) : (
            <Feed publications={filteredPublications} />
          )}
        </animated.div>
      </section>
    </>
  );
}
