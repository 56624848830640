import { animated } from "react-spring";
import { displayEventDate } from "../../../utils/helpers/eventHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { translateY100 } from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { useNavigate } from "react-router-dom";
import { useEvent } from "../../../utils/contexts/eventContext"; // Importez le contexte
import { useContext, useState } from "react";
import { MenuContext } from "../../../utils/contexts/menuContext";
import { isMobile } from "react-device-detect";

const api_url = getImgUrl();

export default function OrganizedEvents({ events, linkEvent }) {
  const animation = translateY100();
  const navigate = useNavigate();
  const { setMenuOpen } = useContext(MenuContext);
  const [isPhone, setIsPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const handleEditEvent = (event) => {
    if (isPhone) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
    navigate(`/eventstudio/${event._id}`);
  };

  return (
    <animated.div style={animation} className="organized_events">
      <div className="organized_events_section">
        <h2 className="text_gradient">Événements créés :</h2>
        <ul>
          {events.map((event) => (
            <li
              key={event._id}
              className="event_card"
              onClick={() => {
                if (!linkEvent) {
                  handleEditEvent(event);
                }
              }}
            >
              <img
                src={`${api_url}/uploads/events/${event.picture}`}
                alt="event thumbnail"
              />
              <p>{event.title}</p>
              <p>{event.location_formatted}</p>
              <p>{displayEventDate(event.date)}</p>
              {linkEvent ? (
                <GradientButton
                  text={"Lier"}
                  onClick={() => linkEvent(event._id)}
                />
              ) : (
                <button>
                  <FontAwesomeIcon icon={faPen} />
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </animated.div>
  );
}
