export default function AppDownload() {
  return (
    <div className="app_download">
      <p>
        Téléchargez l'application. <br />
        (pas encore disponible ⏳)
      </p>
      <div className="store_link">
        <a href="#">
          <img src="../assets/images/appstore.png" alt="logo appstore" />
        </a>
        <a href="#">
          <img src="../assets/images/google-play.png" alt="logo playstore" />
        </a>
      </div>
    </div>
  );
}
