import React, { createContext, useContext, useState, useEffect } from "react";
import io from "socket.io-client";
import { getImgUrl } from "./apiContext";
const api_url = getImgUrl();

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const socket = io(`${api_url}`);

  useEffect(() => {
    socket.on("connect", () => {
      ("Connecté au serveur via Socket.IO");
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
