import React, { useState, useEffect, useRef } from "react";
import SearchInput from "../../../components/Common/Navbar/tmpl/SearchInput";
import { createSale } from "../../../utils/fetchs/saleFetch";
import { UserCard } from "../../../components/Entities/User/UserCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import Switch from "../../../components/Common/Buttons/Switch";

export default function InviteUser({ event }) {
  const { user } = useUser();
  const [guests, setGuests] = useState({
    users: [],
  });

  const handleInvite = async (role, display) => {
    try {
      const saleData = {
        eventId: event.event_id,
        userId: user._id,
        role: role,
        display: display,
      };

      const response = await createSale(user, saleData);

      if (response) {
        //
      } else {
        console.error("Erreur lors de la réservation");
      }
    } catch (error) {
      console.error("Erreur lors de la création de la vente :", error);
    }
  };

  const handleRemoveGuest = (guestId) => {
    setGuests((prevGuests) => ({
      ...prevGuests,
      users: prevGuests.users.filter((guest) => guest._id !== guestId),
    }));
  };

  const handleRoleChange = (guestId, role) => {
    setGuests((prevGuests) => ({
      ...prevGuests,
      users: prevGuests.users.map((guest) =>
        guest._id === guestId ? { ...guest, role } : guest
      ),
    }));
  };

  const handleDisplayChange = (guestId, display) => {
    setGuests((prevGuests) => ({
      ...prevGuests,
      users: prevGuests.users.map((guest) =>
        guest._id === guestId ? { ...guest, display } : guest
      ),
    }));
  };

  return (
    <>
      <div className="discord_col">
        <h2 className="text_gradient second">Inviter des utilisateurs Wive</h2>

        <div className="event_invite">
          <p>Trouver un utilisateur Wive :</p>
          <SearchInput
            data={guests}
            setData={setGuests}
            filter="user"
            showGlass={false}
            showFilter={false}
          />
        </div>
        {guests.users.length === 0 && (
          <p>
            Vous pourrez ensuite associer un rôle qui sera affiché ou non avec
            l'utilisateur sur votre page d'événement Wive en fonction de si vous
            selectionnez l'option. Le role peut servir de catégorie pour ensuite
            retrouver vos listes d'utilisateurs personnalisées.
          </p>
        )}
        {guests.users
          .filter((user) => user._id)
          .map((user) => (
            <div className="guest_infos" key={user._id}>
              <div className="guest">
                <UserCard user={user} link={false} />
              </div>
              <input
                type="text"
                value={user.role || ""}
                onChange={(e) => handleRoleChange(user._id, e.target.value)}
                placeholder="Rôle"
              />
              <Switch
                label="Afficher sur la page de l'événement :"
                value={user.display || false}
                setValue={(value) => handleDisplayChange(user._id, value)}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="icon"
                onClick={() => handleRemoveGuest(user._id)}
              />
            </div>
          ))}
      </div>
    </>
  );
}
