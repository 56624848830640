import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faExclamationTriangle,
  faSignOutAlt,
  faCircleCheck,
  faIdCard,
  faBell,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext";
import LeftColMenu from "../../../components/Common/LeftColMenu/LeftColMenu";
import { MenuContext } from "../../../utils/contexts/menuContext";

export default function AccountMenu({ setSelectedSection, isPhone }) {
  const navigate = useNavigate();
  const { logout } = useUser();
  const { setMenuOpen } = useContext(MenuContext);

  const logoutUser = () => {
    logout();
    navigate("/presentation");
  };

  const handleItemClick = (section) => {
    setSelectedSection(section);
    if (isPhone) {
      setMenuOpen(false);
    }
  };

  const items = [
    {
      icon: faUser,
      label: "Modifier votre compte",
      onClick: () => handleItemClick("account"),
    },
    {
      icon: faLock,
      label: "Changer de mot de passe",
      onClick: () => handleItemClick("password"),
    },
    {
      icon: faCircleCheck,
      label: "Compte professionnel",
      onClick: () => handleItemClick("verifpro"),
    },
    {
      icon: faIdCard,
      label: "Vérifier votre identité",
      onClick: () => handleItemClick("verifprofile"),
    },
    {
      icon: faBell,
      label: "Notifications",
      onClick: () => handleItemClick("notif"),
    },
    {
      icon: faShieldHalved,
      label: "Confidentialité et sécurité",
      onClick: () => handleItemClick("privacy"),
    },
    {
      icon: faExclamationTriangle,
      label: "Signaler un problème",
      onClick: () => handleItemClick("feedback"),
    },
    { icon: faSignOutAlt, label: "Déconnexion", onClick: logoutUser },
  ];

  return (
    <LeftColMenu
      items={items}
      dividerClass="hrgradient"
      pageTitle={"Paramètres de profil"}
    />
  );
}
