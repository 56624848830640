import { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheck,
  faPlus,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { translateYMinus100 } from "../../../utils/animations/animations";
import {
  createConversation,
  deleteConversation,
  getConversationByEventId,
  updateConversation,
} from "../../../utils/fetchs/conversationFetchs";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { getFollowings } from "../../../utils/fetchs/followRequestFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import EventConv from "../../EventPage/tmpl/EventConv";
import SearchInput from "../../../components/Common/Navbar/tmpl/SearchInput";

export default function ConversationEditing({ event }) {
  const [friends, setFriends] = useState(null);
  const { user } = useUser();
  const [searchFriends, setSearchFriends] = useState(null);
  const [conversation, setConversation] = useState({
    type: "event",
    participants: [user._id],
    event_id: event._id,
    title: event.title,
    authorizedSenders: [],
    lastUpdated: null,
  });

  const [authorizedSenders, setAuthorizedSenders] = useState(
    conversation?.authorizedSenders
  );

  // Fetch event conv
  useEffect(() => {
    getConversationByEventId(event._id, user).then((conv) => {
      setConversation(conv);
    });
  }, [event._id, user]);

  const handleSearch = (username) => {
    username === ""
      ? setSearchFriends(null)
      : setSearchFriends(
          friends.filter((f) => f.username.startsWith(username))
        );
  };

  useEffect(() => {
    if (user._id) {
      getFollowings(user, user._id).then((friends) => setFriends(friends));
    }
  }, [user._id]);

  const saveConversation = async () => {
    conversation.lastUpdated = Date.now();
    setConversation(conversation);
    if (!conversation._id) {
      addAuthorizedSender(user._id);
      createConversation(user, conversation).then((conversation) =>
        setConversation(conversation)
      );
    } else {
      updateConversation(user, conversation).then((conversation) =>
        setConversation(conversation)
      );
    }
  };

  const dropConversation = async () => {
    if (conversation._id) {
      await deleteConversation(user, conversation);
    }

    setConversation({
      type: "event",
      participants: [...event.participants, user._id],
      event_id: event._id,
      title: "",
      authorizedSenders: [],
      lastUpdated: null,
    });
  };

  const addAuthorizedSender = (senderId) => {
    conversation.authorizedSenders.push(senderId);
    setConversation(conversation);
    setAuthorizedSenders(conversation.authorizedSenders);
    if (senderId !== user._id) {
      setSearchFriends(searchFriends.filter((f) => f._id !== senderId));
    }
  };

  const removeAuthorizedSender = (senderId) => {
    conversation.authorizedSenders = conversation.authorizedSenders.filter(
      (id) => id !== senderId
    );
    setConversation(conversation);
    setAuthorizedSenders(conversation.authorizedSenders);
    searchFriends.push(friends.find((f) => f._id === senderId));
    setSearchFriends(searchFriends);
  };

  const animation = translateYMinus100();
  return (
    <>
      <div className="conversation_col">
        <h2 className="text_gradient first">Conversation</h2>
        <p className="description">
          Ajoutez des personnes ayant le droit d'envoyer des message dans cette
          conversation :
        </p>
        <SearchInput
          data={conversation}
          setData={setConversation}
          field="participants"
          filter="user"
          showGlass={false}
          showFilter={false}
        />
        {authorizedSenders.map(
          (senderId) =>
            senderId !== user._id && (
              <div key={senderId}>
                <p>{friends.find((f) => f._id === senderId).username}</p>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  onClick={() => removeAuthorizedSender(senderId)}
                />
              </div>
            )
        )}
        <GradientButton
          type={"validate"}
          onClick={() => saveConversation()}
          width={"70%"}
        />

        {conversation?._id && (
          <EventConv eventConv={conversation} user={user} />
        )}
      </div>
    </>
  );
}
