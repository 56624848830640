import React, { useState } from "react";
import { MapContainer } from "react-leaflet";
import MapInner from "../../../components/Entities/Map/MapInner";

export default function EventMap({ event }) {
  const [bounds, setBounds] = useState(null);

  const position = [
    event.location_coordinates[0],
    event.location_coordinates[1],
  ];
  return (
    <div className="hrwrapper">
      <p>Lieu :</p>
      <div className="hrgradient" />
      <p>🇫🇷 {event.location_formatted}</p>

      <div className="map_container">
        <MapContainer
          center={position}
          zoom={13}
          style={{ height: "300px", width: "100%" }}
        >
          <MapInner events={[event]} handleBounds={setBounds} />
        </MapContainer>
      </div>
    </div>
  );
}
