import { useNavigate } from "react-router-dom";
import { getImgUrl } from "../../../utils/contexts/apiContext";

const api_url = getImgUrl();

export default function MultipleUserPictures({ users }) {
  const filteredUsers = users.map((user) => ({
    ...user,
    profilepic:
      user && user.profilepic ? user.profilepic : "ppplaceholder.jpeg",
    username: user.username,
  }));
  const navigate = useNavigate();

  return (
    <>
      {filteredUsers.map((user) => (
        <img
          key={user._id}
          src={`${api_url}/uploads/profilepics/${user.profilepic}`}
          alt="profile_picture"
          className="chatbox_sender_pp chatbox_cover"
          onClick={() => navigate(`/${user.username}`)}
        />
      ))}
    </>
  );
}
