import { useState, useEffect } from "react";
import { generateConversationTitle } from "../../../utils/helpers/conversationHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { sendMessage } from "../../../utils/fetchs/messagesFetchs";
import { getUserConversations } from "../../../utils/fetchs/conversationFetchs";
import Loader from "../../../components/Common/Loader/Loader";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function ShareEventList({ user, event }) {
  const [conversations, setConversations] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const converations = await getUserConversations(user);
      setConversations(
        converations.sort((c1, c2) => c1.lastUpdated > c2.lastUpdated)
      );
    };

    fetchData();
  }, []);

  function retreiveParticipants(conversation) {
    return conversation.participants.filter((p) => p._id !== user._id);
  }

  async function shareEvent() {}

  if (!conversations) {
    return <Loader />;
  }

  return (
    <>
      <div className="hrwrapper">
        <p>Partagez le avec vos amis :</p>
        <div className="hrgradient"></div>
      </div>
      <ul className="share_event_list_container">
        {conversations.map(
          (conv, index) =>
            index < 3 && (
              <li key={conv._id}>
                <div className="share_to_conv_block">
                  <div className="user">
                    <div>
                      {retreiveParticipants(conv).map(
                        (p, index) =>
                          index < 2 && (
                            <img
                              src={`${api_url}/uploads/profilepics/${p.profilepic}`}
                              alt="profile_picture"
                              className="conv_sender_pp"
                            />
                          )
                      )}
                    </div>

                    <p>{generateConversationTitle(conv, user)}</p>
                  </div>

                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="icon"
                    onClick={shareEvent}
                  />
                </div>
              </li>
            )
        )}
      </ul>
    </>
  );
}
