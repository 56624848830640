import { useSpring, animated } from "react-spring";
import { translateXMinus30 } from "../../../utils/animations/animations";

export default function RegisterStep2({
  user,
  setUser,
  usernames,
  checkUsernameValidity,
}) {
  const animation = translateXMinus30()
  
  return (
    <>
      <animated.div style={animation} className="form_group">
        <div className="login_input">
          <input
            type="text"
            name="username"
            placeholder="*Choisissez un pseudo..."
            onChange={(e) => (user["username"] = e.target.value)}
          />
        </div>
      </animated.div>
      <animated.div style={animation} className="form_group">
        <div className="login_input">
          <input
            type="text"
            name="fullName"
            placeholder="*Entrez votre nom complet..."
            onChange={(e) => {
              const fullName = e.target.value;
              const namesArray = fullName.split(/\s+/); // Regex
              const firstname = namesArray[0];
              const lastname = namesArray.slice(1).join(" ");
              user["firstname"] = firstname;
              user["lastname"] = lastname;
            }}
          />
        </div>
      </animated.div>
      <animated.div style={animation} className="form_group">
        <div className="login_input">
          <input
            type="date"
            name="birthdate"
            defaultValue="2014-02-09"
            placeholder="Ecrivez quelques mots sur vous !"
            onChange={(e) => (user["birthdate"] = e.target.value)}
          />
        </div>
      </animated.div>
    </>
  );
}