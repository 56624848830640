import React, { useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import {
  updateUserInfo,
  getAllUsernames,
} from "../../../utils/fetchs/userFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import Loader from "../../../components/Common/Loader/Loader";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { useSpring, animated } from "react-spring";
import { translateXMinus30 } from "../../../utils/animations/animations";
import { uploadProfilePicture } from "../../../utils/fetchs/imageFetch";

const api_url = getImgUrl();

export default function ModifyAccount() {
  const { user, setUser } = useUser();
  const [isPrivate, setIsPrivate] = useState(user.isPrivate);
  const [isModified, setIsModified] = useState(false);

  const [newUsername, setNewUsername] = useState(user.username);
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [birthdate, setBirthdate] = useState(user.birthdate);
  const [phonenumber, setPhonenumber] = useState(user.phonenumber);
  const [description, setDescription] = useState(user.description);
  const [usernames, setUsernames] = useState([]);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const { updateUser } = useUser();
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef();

  useEffect(() => {
    const retrieveUsernames = async () => {
      const usernames = await getAllUsernames();
      setUsernames(usernames);
    };

    retrieveUsernames();
  }, []);

  useEffect(() => {
    if (user) {
      setIsPrivate(user.isPrivate);
      setFirstname(user.firstname || "");
      setLastname(user.lastname || "");
      setBirthdate(user.birthdate || "");
      setPhonenumber(user.phonenumber || "");
      setDescription(user.description || "");
      setImagePreview(`${api_url}/uploads/profilepics/${user.profilepic}`);
    }
  }, [user]);

  const handleInputChange = (setStateFunction, newValue) => {
    setStateFunction(newValue);
    setIsModified(true);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    const profilepicurl = await uploadProfilePicture(file);
    user.profilepic = profilepicurl;
    const updatedUser = await updateUserInfo(user, "profilepic", profilepicurl);
    if (updatedUser) {
      user.profilepic = profilepicurl;
      updateUser(user, "profilepic", profilepicurl);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const updateUserStatus = async () => {
    //change only if value is different
    if (isPrivate !== user.isPrivate) {
      const updatedUser = await updateUserInfo(user, "isPrivate", isPrivate);

      if (updatedUser) {
        user.isPrivate = isPrivate;
        updateUser(user, "isPrivate", isPrivate);
        setHasBeenUpdated(true);
      }
    }

    if (isUsernameAvailable) {
      const updatedUser = await updateUserInfo(user, "username", newUsername);

      if (updatedUser) {
        user.username = newUsername;
        updateUser(user, "username", newUsername);
        setHasBeenUpdated(true);
      }
    }

    if (firstname !== user.firstname) {
      await updateUserInfo(user, "firstname", firstname);
    }
    if (lastname !== user.lastname) {
      await updateUserInfo(user, "lastname", lastname);
    }
    if (birthdate !== user.birthdate) {
      await updateUserInfo(user, "birthdate", birthdate);
    }
    if (phonenumber !== user.phonenumber) {
      await updateUserInfo(user, "phonenumber", phonenumber);
    }
    if (description !== user.description) {
      await updateUserInfo(user, "description", description);
    }

    if (user.profilepic) {
    }
  };

  const checkUsernameValidity = async (username) => {
    setIsModified(true);
    setNewUsername(username);
    if (usernames.includes(username) && user.username != username) {
      setIsUsernameAvailable(false);
    } else {
      if (username === "") {
        setIsUsernameAvailable(false);
      } else {
        setIsUsernameAvailable(true);
        setNewUsername(username);
      }
    }
  };

  const animation = translateXMinus30();

  if (!usernames.length || !user) {
    return <Loader />;
  }

  return (
    <>
      <section className="modify">
        <animated.article className="user">
          <div className="second_left_col">
            {imagePreview ? (
              <div className="image_container">
                <img
                  src={imagePreview}
                  alt="Profile Preview"
                  className="profile_picture"
                  id="outputpfp"
                  onClick={triggerFileInput}
                />
              </div>
            ) : (
              <img
                src="./assets/images/plus.png"
                alt="Bouton plus"
                className="profile_picture"
                id="outputpfp"
                onClick={triggerFileInput}
                style={{ cursor: "pointer" }}
              />
            )}
            <input
              type="file"
              id="avatar"
              name="avatar"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="second_right_col">
            <p>@{user.username}</p>
            <p onClick={triggerFileInput} style={{ cursor: "pointer" }}>
              Modifier la photo de profil
            </p>
          </div>
        </animated.article>
        {!user.profilepic && (
          <>
            <p className="profile_pic_txt">Ajouter une photo de profil</p>
          </>
        )}
        <article className="name">
          <div className="second_left_col">
            <p>Nom d'utilisateur</p>
          </div>
          <div className="second_right_col">
            <input
              type="text"
              value={newUsername}
              placeholder="Nom d'utilisateur"
              onChange={(e) => checkUsernameValidity(e.currentTarget.value)}
            />
            {newUsername != user.username && !isUsernameAvailable && (
              <p>Ce nom d'utilisateur n'est pas disponible</p>
            )}
            <div className="hrgradient"></div>
            <p>
              Aidez les gens à trouver votre compte à l’aide de votre nom le
              plus connu, que ce soit votre nom complet, votre surnom ou votre
              nom d’entreprise. Vous pouvez uniquement changer votre nom deux
              fois tous les 14 jours.
            </p>
          </div>
        </article>
        <article className="status">
          <div className="second_left_col">
            <p>Compte privé</p>
          </div>
          <div className="second_right_col">
            <label className="switch">
              <input
                type="checkbox"
                defaultChecked={isPrivate}
                onChange={(e) =>
                  handleInputChange(setIsPrivate, e.currentTarget.checked)
                }
              />
              <span className="slider round"></span>
            </label>
          </div>
        </article>
        <article className="name">
          <div className="second_left_col">
            <p>Nom complet</p>
          </div>
          <div className="second_right_col">
            <div className="input_flex">
              <input
                type="text"
                placeholder="Nom"
                value={firstname}
                onChange={(e) =>
                  handleInputChange(setFirstname, e.currentTarget.value)
                }
              />
              <input
                type="text"
                placeholder="Prénom"
                value={lastname}
                onChange={(e) =>
                  handleInputChange(setLastname, e.currentTarget.value)
                }
              />
            </div>
          </div>
        </article>
        <article>
          <div className="second_left_col">
            <p>Date de naissance</p>
          </div>
          <div className="second_right_col">
            <input
              type="date"
              value={birthdate}
              onChange={(e) =>
                handleInputChange(setBirthdate, e.currentTarget.value)
              }
            />
          </div>
        </article>
        <article>
          <p className="second_left_col">Numéro de téléphone</p>
          <div className="second_right_col">
            <PhoneInput
              country={"fr"}
              value={phonenumber}
              onChange={(e) =>
                handleInputChange(setPhonenumber, e.currentTarget.value)
              }
            />
          </div>
        </article>
        <article>
          <div className="second_left_col">
            <p>Description</p>
          </div>
          <div className="second_right_col">
            <textarea
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) =>
                handleInputChange(setDescription, e.currentTarget.value)
              }
            />
          </div>
        </article>
        <div className="flex">
          {isModified && (
            <GradientButton
              icon={faFloppyDisk}
              text={"Sauvegarder"}
              onClick={() => updateUserStatus()}
              padding={"10px"}
            />
          )}
        </div>
      </section>
    </>
  );
}
