import React from "react";
import { useSpring, animated } from "react-spring";

export default function Loader() {
  const { scale, opacity } = useSpring({
    from: { scale: 0, opacity: 0 },
    to: async (next) => {
      await next({ scale: 1.2, opacity: 1 });
      while (1) {
        await next({ scale: 1 });
        await next({ scale: 1.1 });
      }
    },
    config: {
      tension: 200,
      friction: 5,
    },
  });

  return (
    <div className="loader_container">
      <animated.img
        style={{
          transform: scale.to((s) => `scale(${s})`),
          opacity: opacity,
        }}
        src="/assets/images/logo.png"
        alt="Logo"
        className="loader_logo"
      />
      <p className="loader_text">
        Si le chargement prend trop de temps, veuillez rafraîchir la page.
      </p>
      <p className="loader_text">
        Si le problème persiste, veuillez réessayer plus tard.
      </p>
    </div>
  );
}
