import React, { useEffect, useState, useContext } from "react";
import { useSpring, animated } from "react-spring";
import { Portal } from "react-portal";
import updateHead from "../../../utils/helpers/updatePageHead";
import { getUserConversations } from "../../../utils/fetchs/conversationFetchs";
import { ChatboxConversation } from "../../../pages/Chatbox/Conversations/ChatboxConversation";
import { ChatboxContext } from "../../../utils/contexts/chatboxContext";
import { CreaGroupConversation } from "../CreaConversation/CreaGroupConv";

export function ShareMessage({
  user,
  shouldBeOpen,
  setShouldBeOpen,
  sharedPubli,
}) {
  const [conversations, setConversations] = useState(null);
  const [filteredConversations, setFilteredConversations] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cssPath = "/assets/css/components/share_message.css"; // Set the CSS to import
  const { setActiveConv } = useContext(ChatboxContext);

  const openModal = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    const getConversationsList = async () => {
      const conversations = await getUserConversations(user);
      setConversations(conversations);
      setFilteredConversations(conversations);
    };

    if (shouldBeOpen && !conversations) {
      getConversationsList();
    }

    if (shouldBeOpen && conversations) {
      openModal();
    }
  }, [shouldBeOpen, conversations]);

  const closeModal = () => {
    setModalIsOpen(false);
    setShouldBeOpen(false);
  };

  const modalTransition = useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });

  const opacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  if (modalIsOpen && !conversations) {
    return <CreaGroupConversation />;
  }

  const handleSearch = (value) => {
    const searchQuery = value.toLowerCase();
    setFilteredConversations(
      conversations.filter((conversation) => {
        return conversation.participants.some((participant) =>
          participant.username.toLowerCase().startsWith(searchQuery)
        );
      })
    );
  };

  return (
    <>
      {updateHead({ cssPath })}
      {modalIsOpen && (
        <Portal>
          <animated.div
            className="modal_backdrop"
            style={opacityTransition}
            onClick={closeModal}
          ></animated.div>
          <animated.div className="modal_box" style={modalTransition}>
            <div className="modal_follow_title_container">
              <h2>Partager cet événement avec vos amis 👇</h2>
              <input
                className="conv_search"
                type="text"
                placeholder="Rechercher"
                onChange={(e) => handleSearch(e.target.value)}
              ></input>
            </div>

            <ul className="modal_follow_list">
              {filteredConversations.map((conv, index) => (
                <ChatboxConversation
                  key={conv._id}
                  conversation={conv}
                  actualUser={user}
                  activeConv={null}
                  handleActiveConv={setActiveConv}
                  detailSendersList={true}
                  sharedPubli={sharedPubli}
                />
              ))}
            </ul>
          </animated.div>
        </Portal>
      )}
    </>
  );
}
