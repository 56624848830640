import React from "react";
import updateHead from "../../../utils/helpers/updatePageHead";
import { ChatboxChatHeader } from "../../Chatbox/Chat/ChatboxChatHeader";
import { ChatboxChat } from "../../Chatbox/Chat/ChatboxChat";

export default function EventConv({ eventConv, user }) {
  const cssPath = "/assets/css/pages/chatbox.css";
  return (
    <>
      {updateHead({ cssPath })}
      <div className="event_conversation">
        <ChatboxChatHeader activeConv={eventConv} actualUser={user} />
        <ChatboxChat activeConv={eventConv} actualUser={user} />
      </div>
    </>
  );
}
