import { getEventById } from "../../../../utils/fetchs/eventFetchs";
import { getLikes } from "../../../../utils/fetchs/likeFetchs";
import {
  getEventParticipantsIfUserIsParticipant,
  getSalesByEventId,
} from "../../../../utils/fetchs/saleFetch";

export default async function getEventInfos({
  eventId,
  loggedInUser,
  setLikesCount,
  setIsLiked,
  setParticipantCount,
  setParticipants,
  setEvent,
}) {
  try {
    let eventDetails;
    if (eventId && !eventId._id) {
      eventDetails = await getEventById(eventId, loggedInUser);
      setEvent(eventDetails);
    } else {
      eventDetails = eventId;
    }

    const likes = await getLikes(eventDetails._id, "Event");
    setLikesCount(likes.length);
    setIsLiked(likes.some((like) => like.user === loggedInUser._id));

    const participants = await getSalesByEventId(
      loggedInUser,
      eventDetails._id
    );
    setParticipantCount(participants);

    const participantsList = await getEventParticipantsIfUserIsParticipant(
      loggedInUser,
      eventDetails._id
    );

    if (participantsList) {
      setParticipants(participantsList);
    }
  } catch (error) {
    console.error("Failed to fetch event or participants", error);
  }
}
