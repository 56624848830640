import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faImage, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import {
  createStep,
  deleteStep,
  getStepsByEventId,
  updateStep,
} from "../../../utils/fetchs/stepFetchs";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import Switch from "../../../components/Common/Buttons/Switch";
import CreateListModal from "../../../components/Modals/CreateListModal/CreateListModal";
import { createList, getListByUserId } from "../../../utils/fetchs/listFetchs";
import EventSteps from "../../EventPage/tmpl/EventSteps";

export default function StepCol({
  generalStepsContent,
  setGeneralStepsContent,
  event,
  discordServer,
  tickets,
}) {
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef();
  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [availableLists, setAvailableLists] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    async function fetchLists() {
      const lists = await getListByUserId(user);
      setAvailableLists(lists);
    }
    fetchLists();
  }, [user, event._id]);

  const updateGeneralSettings = (field, value) => {
    setGeneralStepsContent((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (field === "list" && value === "createList") {
      setShowCreateListModal(true);
    }
  };

  const handleCreateList = async (listData) => {
    const newList = await createList(user, listData);
    setAvailableLists((prevLists) => [...prevLists, newList]);
    updateGeneralSettings("list", newList._id); // Sélectionner la nouvelle liste
    setShowCreateListModal(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    const updatedSteps = [...generalStepsContent.steps];
    updatedSteps[0] = { ...updatedSteps[0], content_picture: file };
    setGeneralStepsContent({ ...generalStepsContent, steps: updatedSteps });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const updateLocalStep = (index, field, value) => {
    const newSteps = [...generalStepsContent.steps];
    if (field === "permissions") {
      newSteps[index].permissions = value;
    } else if (field === "list" && value === "createList") {
      setShowCreateListModal(true);
    } else {
      newSteps[index][field] = value;
    }
    setGeneralStepsContent({ ...generalStepsContent, steps: newSteps });
  };

  const addStep = () => {
    const newStep = {
      _id: null,
      title: "",
      content: "",
      content_picture: "",
      date: null,
      permissions: "public",
      list: null,
      isEditing: true,
      polls: [],
    };
    setGeneralStepsContent({
      ...generalStepsContent,
      steps: [...generalStepsContent.steps, newStep],
    });
  };

  const dropStep = async (index) => {
    await deleteStep(generalStepsContent.steps[index]);
    const newSteps = generalStepsContent.steps.filter((_, i) => i !== index);
    setGeneralStepsContent({ ...generalStepsContent, steps: newSteps });
  };

  const saveStep = async (index) => {
    let newSteps = [...generalStepsContent.steps];
    const toUpdateStep = index && newSteps[index];
    if (index) {
      newSteps[index].isEditing = false;
    } else {
      newSteps = generalStepsContent.steps.map((step) => ({
        ...step,
        isEditing: false,
      }));
    }

    const reponse = !generalStepsContent._id
      ? await createStep(user, generalStepsContent, newSteps)
      : await updateStep(
          user,
          generalStepsContent,
          toUpdateStep ? newSteps : toUpdateStep
        );

    setGeneralStepsContent({ ...generalStepsContent, reponse });
  };

  const addPoll = (stepIndex) => {
    const newPoll = {
      title: "",
      description: "",
      date: new Date().toISOString().slice(0, -1),
      status: "pending",
      choices: [{ text: "", votes: [] }],
    };
    const updatedSteps = [...generalStepsContent.steps];
    updatedSteps[stepIndex].polls = [
      ...(updatedSteps[stepIndex].polls || []),
      newPoll,
    ];
    setGeneralStepsContent({ ...generalStepsContent, steps: updatedSteps });
  };

  const updatePoll = (stepIndex, pollIndex, field, value) => {
    const updatedSteps = [...generalStepsContent.steps];
    updatedSteps[stepIndex].polls[pollIndex][field] = value;
    setGeneralStepsContent({ ...generalStepsContent, steps: updatedSteps });
  };

  const dropPoll = (stepIndex, pollIndex) => {
    const updatedSteps = [...generalStepsContent.steps];
    updatedSteps[stepIndex].polls = updatedSteps[stepIndex].polls.filter(
      (_, index) => index !== pollIndex
    );
    setGeneralStepsContent({ ...generalStepsContent, steps: updatedSteps });
  };

  const updateChoice = (stepIndex, pollIndex, choiceIndex, value) => {
    const updatedSteps = [...generalStepsContent.steps];
    updatedSteps[stepIndex].polls[pollIndex].choices[choiceIndex].text = value;
    setGeneralStepsContent({ ...generalStepsContent, steps: updatedSteps });
  };

  const addChoice = (stepIndex, pollIndex) => {
    const updatedSteps = [...generalStepsContent.steps];
    updatedSteps[stepIndex].polls[pollIndex].choices.push({
      text: "",
      votes: [],
    });
    setGeneralStepsContent({ ...generalStepsContent, steps: updatedSteps });
  };

  const dropChoice = (stepIndex, pollIndex, choiceIndex) => {
    const updatedSteps = [...generalStepsContent.steps];
    updatedSteps[stepIndex].polls[pollIndex].choices = updatedSteps[
      stepIndex
    ].polls[pollIndex].choices.filter((_, index) => index !== choiceIndex);
    setGeneralStepsContent({ ...generalStepsContent, steps: updatedSteps });
  };
  const handleEditStep = () => {
    const newSteps = generalStepsContent.steps.map((step) => ({
      ...step,
      isEditing: true,
    }));
    setGeneralStepsContent({ ...generalStepsContent, steps: newSteps });
  };

  return (
    <ul className="step_col">
      <h2 className="text_gradient second">Étapes d'événement</h2>
      <p>Paramètres généraux :</p>
      <input
        type="text"
        placeholder="Titre des étapes"
        value={generalStepsContent.title}
        onChange={(e) => updateGeneralSettings("title", e.target.value)}
      />
      <div className="ticket_flex_between">
        <Switch
          label="Carte des étapes :"
          value={generalStepsContent.map}
          setValue={(value) => updateGeneralSettings("map", value)}
          tooltip="Affichera une carte des étapes ayant une adresse sur votre page d'événement."
        />
        <label>
          Visibilité des étapes :
          <select
            value={generalStepsContent.permissions}
            onChange={(e) =>
              updateGeneralSettings("permissions", e.target.value)
            }
          >
            <option value="public">Tout le monde</option>
            <option value="participants">Participants uniquement</option>
            <option value="list">Certains participants uniquement</option>
          </select>
        </label>
      </div>
      {generalStepsContent.permissions.startsWith("list") && (
        <div className="ticket_flex_between">
          <label>
            Participants autorisés :
            <select
              value={generalStepsContent.list}
              onChange={(e) => updateGeneralSettings("list", e.target.value)}
            >
              <option value="select">-Sélectionner une liste-</option>
              {availableLists.map((list) => (
                <option key={list._id} value={list._id}>
                  {list.name}
                </option>
              ))}
              <option value="createList">Créer une liste</option>
            </select>
          </label>
          <GradientButton
            text={"Créer une liste"}
            icon={faPlus}
            onClick={() => setShowCreateListModal(true)}
          />
        </div>
      )}
      {generalStepsContent.steps.map(
        (step, stepIndex) =>
          step.isEditing && (
            <li key={stepIndex} className="post">
              <div>
                <p>Paramètres de l'étape</p>
                <div className="ticket_flex">
                  <input
                    type="text"
                    placeholder="Titre de l'étape"
                    value={step.title}
                    onChange={(e) =>
                      updateLocalStep(stepIndex, "title", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    placeholder="Lieu de l'étape"
                    className="step_location"
                    value={step.location}
                    onChange={(e) =>
                      updateLocalStep(stepIndex, "location", e.target.value)
                    }
                  />
                </div>
                <div className="ticket_flex_between">
                  <label className="inline">
                    Date et heure de l'étape :
                    <input
                      type="datetime-local"
                      value={step.date}
                      onChange={(e) =>
                        updateLocalStep(stepIndex, "date", e.target.value)
                      }
                    />
                  </label>
                  <label className="inline">
                    Visibilité de l'étape :
                    <select
                      value={step.permissions}
                      onChange={(e) =>
                        updateLocalStep(
                          stepIndex,
                          "permissions",
                          e.target.value
                        )
                      }
                    >
                      <option value="public">Tout le monde</option>
                      <option value="participants">
                        Participants uniquement
                      </option>
                      <option value="list">
                        Certains participants uniquement
                      </option>
                    </select>
                  </label>
                </div>
                {step.permissions.startsWith("list") && (
                  <div className="ticket_flex_between">
                    <label>
                      Participants autorisés :
                      <select
                        value={step.list}
                        onChange={(e) =>
                          updateLocalStep(stepIndex, "list", e.target.value)
                        }
                      >
                        <option value="select">-Sélectionner une liste-</option>
                        {availableLists.map((list) => (
                          <option key={list._id} value={list._id}>
                            {list.name}
                          </option>
                        ))}
                        <option value="createList">Créer une liste</option>
                      </select>
                    </label>
                    <GradientButton
                      text={"Créer une liste"}
                      icon={faPlus}
                      onClick={() => setShowCreateListModal(true)}
                    />
                  </div>
                )}
                <div className="image_container">
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="step Thumbnail"
                      className="picture"
                      onClick={triggerFileInput}
                    />
                  ) : (
                    <button onClick={triggerFileInput}>
                      <FontAwesomeIcon icon={faImage} /> Ajouter une image
                    </button>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
                <label>
                  Contenu :
                  <textarea
                    value={step.content}
                    onChange={(e) =>
                      updateLocalStep(stepIndex, "content", e.target.value)
                    }
                  />
                </label>
                {step.polls.map((poll, pollIndex) => (
                  <section id="poll" key={pollIndex}>
                    <div className="flex">
                      <div className="flex_column">
                        <input
                          type="text"
                          placeholder="Titre du sondage"
                          value={poll.title}
                          onChange={(e) =>
                            updatePoll(
                              stepIndex,
                              pollIndex,
                              "title",
                              e.target.value
                            )
                          }
                        />
                        <textarea
                          placeholder="Description du sondage"
                          value={poll.description}
                          onChange={(e) =>
                            updatePoll(
                              stepIndex,
                              pollIndex,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        {poll.choices.map((choice, choiceIndex) => (
                          <div className="flex_center flex_margin">
                            <input
                              key={choiceIndex}
                              type="text"
                              placeholder="Option"
                              value={choice.text}
                              onChange={(e) =>
                                updateChoice(
                                  stepIndex,
                                  pollIndex,
                                  choiceIndex,
                                  e.target.value
                                )
                              }
                            />
                            <GradientButton
                              round={true}
                              icon={faTrash}
                              onClick={() =>
                                dropChoice(stepIndex, pollIndex, choiceIndex)
                              }
                            />
                          </div>
                        ))}
                        <div className="flex_center">
                          <GradientButton
                            round={true}
                            icon={faPlus}
                            onClick={() => addChoice(stepIndex, pollIndex)}
                          />
                          <p>Ajouter un choix</p>
                        </div>
                      </div>
                    </div>
                    <GradientButton
                      type="delete"
                      text="le sondage"
                      onClick={() => dropPoll(stepIndex, pollIndex)}
                    />
                  </section>
                ))}
                <div className="flex_center">
                  <GradientButton
                    round={true}
                    icon={faPlus}
                    onClick={() => addPoll(stepIndex)}
                  />
                  <p>Ajouter un sondage</p>
                </div>
                <div className="ticket_flex sm">
                  <GradientButton
                    type="validate"
                    onClick={() => saveStep(stepIndex)}
                  />
                  <GradientButton
                    type="delete"
                    onClick={() => dropStep(stepIndex)}
                  />
                </div>
              </div>
            </li>
          )
      )}
      <EventSteps eventId={event._id} />
      <div className="ticket_flex sm">
        <GradientButton type="edit" onClick={() => handleEditStep()} />
      </div>
      <div className="flex_center">
        <GradientButton
          icon={faPlus}
          text="Ajouter une étape"
          padding="10px"
          onClick={addStep}
        />

        <GradientButton type="save" onClick={() => saveStep()} padding="10px" />
      </div>
      {showCreateListModal && (
        <CreateListModal
          discordRoles={discordServer.roles}
          tickets={tickets}
          createdFor={event._id}
          onSave={handleCreateList}
          shouldBeOpen={showCreateListModal}
          setShouldBeOpen={setShowCreateListModal}
        />
      )}
    </ul>
  );
}
