export default function renderTextWithLinks(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g; // Regex pour détecter les URL

  // Remplace les URL par des balises <a>
  const parts = text.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part; // retourne le texte tel quel s'il n'est pas un lien
  });

  return parts;
}
