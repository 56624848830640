import React, { useEffect, useState } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import { useUser } from "../../utils/contexts/userContext";
import Loader from "../../components/Common/Loader/Loader";
import { UserCard } from "../../components/Entities/User/UserCard";
import BouncyVerified from "../Discord/BouncyVerified";

export default function Google() {
  const cssPath = "/assets/css/pages/discord.css";
  const title = "Wive | Connexion avec Google";
  const [isLoading, setIsLoading] = useState(false);
  const { user, login } = useUser();
  const queryParams = new URLSearchParams(location.search);
  const googleToken = queryParams.get("access_token");
  const state = queryParams.get("state");

  // Envoyer le jeton à la fenêtre principale
  if (googleToken && state) {
    window.opener.postMessage({ googleToken, state }, window.location.origin);
  }

  // Fermer la fenêtre après un court délai
  setTimeout(() => window.close(), 100);
  return (
    <>
      {updateHead({ title, cssPath })}
      <div className="form_content">
        <div className="gilbert_message">
          <UserCard user={"gilbert"} />
          <p>Gilbert communique avec Google...</p>
        </div>
        {isLoading && <Loader />}
      </div>
      {googleToken && (
        <div className="form_content">
          <div className="flex_center">
            <UserCard user={user} />
            <BouncyVerified />
          </div>
          <p>Vous êtes connecté. Redirection en cours.</p>
        </div>
      )}
    </>
  );
}
