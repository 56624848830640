import React from "react";

export function SpinningLoader() {
  return (
    <>
      <div className="spinner">
        <div className="spinner__circle">
          <div className="spinner__circle-gradient"></div>

          <div className="spinner__circle-inner"></div>
        </div>
      </div>
    </>
  );
}
