import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    id: "",
    email: "",
    phonenumber: "",
    password: "",
    confirmPassword: "",
    username: "",
    lastname: "",
    firstname: "",
    profilepic: "",
    verified: false,
    token: "",
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (userData, userToken) => {
    setUser({ ...userData, token: userToken });
    localStorage.setItem(
      "user",
      JSON.stringify({ ...userData, token: userToken })
    );
  };

  const updateUser = (updatedUserData) => {
    setUser(updatedUserData);
    localStorage.setItem("user", JSON.stringify(updatedUserData));
  };

  const addConversation = (newConversation) => {
    setUser((prevUser) => ({
      ...prevUser,
      conversations: [...prevUser.conversations, newConversation],
    }));
  };

  const logout = () => {
    setUser({
      id: "",
      email: "",
      phonenumber: "",
      password: "",
      confirmPassword: "",
      username: "",
      lastname: "",
      firstname: "",
      profilepic: "",
      verified: false,
      token: "",
    });
    localStorage.removeItem("user");
  };

  const value = {
    user,
    setUser,
    updateUser,
    login,
    logout,
    addConversation,
    token: user.token,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
