import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useUser } from "../../utils/contexts/userContext";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import updateHead from "../../utils/helpers/updatePageHead";
import ModifyAccount from "./Subpages/ModifyAccount";
import ModifyNotif from "./Subpages/ModifyNotif";
import VerifProfile from "./Subpages/VerifProfile";
import ModifyPassword from "./Subpages/ModifyPassword";
import Feedback from "./Subpages/Feedback";
import AccountMenu from "./tmpl/AccountMenu";
import { MenuContext } from "../../utils/contexts/menuContext";

export default function Account() {
  const cssPath = "/assets/css/pages/account.css";
  const title = "Wive | Modifier votre compte";

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const defaultSection = params.get("section") || "account";

  const { user } = useUser();
  const [selectedSection, setSelectedSection] = useState(defaultSection);
  const [isPhone, setIsPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const { menuOpen, setMenuOpen } = useContext(MenuContext);
  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setIsPhone(isMobile || window.innerWidth < 767.98);
      setMenuOpen(!isMobile || window.innerWidth > 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(isPhone);
  return (
    <>
      <main>
        <BackgroundGradientLur />
        {updateHead({ title, cssPath })}

        <AccountMenu
          setSelectedSection={setSelectedSection}
          isPhone={isPhone}
        />

        {(isPhone && !menuOpen) || !isPhone ? (
          <div className="right_col">
            {(() => {
              switch (selectedSection) {
                case "account":
                  return <ModifyAccount user={user} />;
                case "notif":
                  return <ModifyNotif />;
                case "password":
                  return <ModifyPassword user={user} />;
                case "feedback":
                  return <Feedback />;
                case "verifprofile":
                  return <VerifProfile user={user} />;
                default:
                  return null;
              }
            })()}
          </div>
        ) : null}
      </main>
    </>
  );
}
