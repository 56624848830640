import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Portal } from "react-portal";
import updateHead from "../../../utils/helpers/updatePageHead";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchInput from "../../Common/Navbar/tmpl/SearchInput";
import { useUser } from "../../../utils/contexts/userContext";
import GradientButton from "../../Common/Buttons/GradientButton";
const api_url = getImgUrl();

export default function CreateListModal({
  discordRoles,
  tickets,
  createdFor,
  userList,
  shouldBeOpen,
  setShouldBeOpen,
  onSave,
}) {
  const { user } = useUser();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [createdList, setCreatedList] = useState({
    creator: user._id,
    name: "",
    roles: [],
    users: [],
    createdFor: createdFor,
  });
  const [users, setUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cssPath = "/assets/css/components/create_list_modal.css"; // Set the CSS to import

  useEffect(() => {
    setUsers(userList);
  }, [userList]);

  useEffect(() => {
    if (shouldBeOpen) {
      openModal();
    } else {
      closeModal();
    }
  }, [shouldBeOpen]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setShouldBeOpen(false);
  };

  const handleSave = () => {
    onSave(createdList);
    closeModal();
  };
  const handleRoleChange = (e) => {
    const { options, selectedIndex } = e.target;
    const listRoles = new Set(createdList.roles);
    const selectedValue = options[selectedIndex].value;

    if (listRoles.has(selectedValue)) {
      listRoles.delete(selectedValue);
    } else {
      listRoles.add(selectedValue);
    }
    setSelectedRoles(selectedValue);
    setCreatedList((prevList) => ({
      ...prevList,
      roles: Array.from(listRoles),
    }));
  };

  const handleNameChange = (e) => {
    setCreatedList((prevList) => ({
      ...prevList,
      name: e.target.value,
    }));
  };

  const handleTicketChange = (e) => {
    const { options, selectedIndex } = e.target;
    const listRoles = new Set(createdList.roles);
    const selectedValue = options[selectedIndex].value;
    if (listRoles.has(selectedValue)) {
      listRoles.delete(selectedValue);
    } else {
      listRoles.add(selectedValue);
    }

    setCreatedList((prevList) => ({
      ...prevList,
      roles: Array.from(listRoles),
    }));
  };

  const handleCategoryChange = (e) => {
    const { options, selectedIndex } = e.target;
    const listRoles = new Set(createdList.roles);
    const selectedValue = options[selectedIndex].value;

    if (listRoles.has(selectedValue)) {
      listRoles.delete(selectedValue);
    } else {
      listRoles.add(selectedValue);
    }

    setCreatedList((prevList) => ({
      ...prevList,
      roles: Array.from(listRoles),
    }));
  };

  const handleUserChange = (selectedUsers) => {
    setCreatedList((prevList) => ({
      ...prevList,
      users: selectedUsers,
    }));
  };
  const mapIdsToNames = (ids, roles) => {
    return ids.map((id) => {
      const role = roles.find((role) => role.id === id);
      return role ? role.name : id; // Si le rôle n'est pas trouvé, retourne l'ID
    });
  };
  const listToString = (list, discordRoles) => {
    const rolesNames = mapIdsToNames(list.roles, discordRoles);
    const rolesString = rolesNames.join(", ");
    const usersString = list.users.join(", ");
    return `Nom : ${list.name}\nRôles: ${rolesString}\nUtilisateurs: ${usersString}`;
  };

  const modalTransition = useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });

  const opacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  return (
    <>
      {updateHead({ cssPath })}
      {modalIsOpen && (
        <Portal>
          <animated.div
            className="modal_backdrop"
            style={opacityTransition}
            onClick={closeModal}
          ></animated.div>
          <animated.div className="modal_box" style={modalTransition}>
            <button className="close_modal_button" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="modal_follow_title_container">
              <h2>Créer une liste</h2>
            </div>

            <div className="modal_inner">
              <p>
                Votre liste : <br />
                {listToString(createdList, discordRoles)}
              </p>
              <div>
                <label>
                  Nom :
                  <input
                    type="text"
                    name="name"
                    defaultValue={createdList.name}
                    onChange={handleNameChange}
                    placeholder="Nom de votre liste"
                  />
                </label>
                <h3>Rechercher des utilisateurs</h3>
                <SearchInput
                  data={createdList.users}
                  setData={handleUserChange}
                  field="selectedUsers"
                  filter="user"
                  showGlass={false}
                  showFilter={false}
                />
              </div>
              <div>
                <h3 className="text_gradient">Rôles Discord</h3>
                <select
                  multiple
                  value={selectedRoles}
                  onChange={handleRoleChange}
                >
                  {discordRoles.map((role, index) => (
                    <option key={index} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <h3 className="text_gradient">Tickets</h3>
                <select
                  multiple
                  value={selectedTickets}
                  onChange={handleTicketChange}
                >
                  {tickets.map((ticket, index) => (
                    <option key={index} value={ticket.name}>
                      {ticket.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <h3 className="text_gradient">Catégories de Tickets</h3>
                <select
                  multiple
                  value={selectedCategories}
                  onChange={handleCategoryChange}
                >
                  {tickets.map(
                    (ticket, index) =>
                      ticket.category && (
                        <option key={index} value={ticket.category}>
                          {ticket.category}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
            <GradientButton type="save" onClick={handleSave} />
          </animated.div>
        </Portal>
      )}
    </>
  );
}
