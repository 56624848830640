import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

async function fetchSale(user, endpoint, method = "GET", body = null) {
  try {
    const tk = await user.token;
    const options = {
      method,
      headers: {
        Authorization: "Bearer " + tk,
        "Content-Type": "application/json",
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${api_url}/sale/${endpoint}`, options);

    switch (response.status) {
      case 200:
      case 201:
        return await response.json();
      case 400:
        throw new Error("Erreur de validation");
      case 401:
        return (window.location.href = "/login-register?tk=true");
      case 404:
        throw new Error("Ressource non trouvée");
      default:
        throw new Error("Erreur inconnue");
    }
  } catch (error) {
    console.error("Erreur :", error);
  }
}

// Récupérer toutes les ventes liées à un utilisateur
export async function getSalesByUserId(user, userId) {
  return fetchSale(user, `user/${userId}`);
}

// Récupérer toutes les ventes liées à un événement
export async function getSalesByEventId(user, eventId) {
  return fetchSale(user, `count/${eventId}`);
}

// Savoir si l'user participe à un event
export async function checkUserParticipation(user, eventId) {
  return fetchSale(user, `event/${eventId}/${user._id}`);
}

// Créer une vente
export async function createSale(user, sale) {
  return fetchSale(user, "create", "POST", sale);
}

// Supprimer une vente
export async function deleteSale(user, saleId) {
  return fetchSale(user, `delete/${saleId}`, "DELETE");
}

// Mettre à jour une vente
export async function updateSale(user, saleId, sale) {
  return fetchSale(user, `update/${saleId}`, "PATCH", sale);
}

// Récupérer la liste des participants d'un événement si l'utilisateur fait partie des participants
export async function getEventParticipantsIfUserIsParticipant(user, eventId) {
  return fetchSale(user, `event/list/${eventId}/${user._id}`, "POST");
}
