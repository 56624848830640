import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useSpring, animated } from "react-spring";

export default function PublicationSettings({ publication }) {
  const [openSettings, setOpenSettings] = useState(false);
  const animation = useSpring({
    from: { opacity: 0, transform: "translateY(-100%)" },
    to: { opacity: 1, transform: "translateY(0%)" },
  });
  return (
    <>
      <FontAwesomeIcon
        icon={faCog}
        className="icon"
        onClick={() => setOpenSettings(!openSettings)}
      />
      {openSettings ? (
        <animated.ul
          style={animation}
          className="pulication_settings_list text_gradient_hover"
        >
          <li>
            <p>Ne plus voir cette publication</p>
          </li>
          <li>
            <p>Ne plus voir les publications liées à ce compte</p>
          </li>
          <li>
            <p>Se désabonner du compte</p>
          </li>
        </animated.ul>
      ) : (
        ""
      )}
    </>
  );
}
