import React, { useEffect, useState } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import dayjs from "dayjs";
import CheckoutForm from "./tmpl/CheckoutForm";
import Loader from "../../components/Common/Loader/Loader";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { createPayementIntent } from "../../utils/fetchs/payementFetchs";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getImgUrl } from "../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function Checkout() {
  const location = useLocation();
  const event = location.state[0];
  const ticket = location.state[1];

  const [clientSecret, setClientSecret] = useState(null);

  const cssPath = "/assets/css/pages/checkout.css";
  const title = ticket.username;

  const stripePromise = loadStripe(
    "pk_live_51K71eeE3hz8K6E3coo89tXdKZqfgCHfXcZHtqtH43u5TWtLCI8XnHgyCBzfLErRTqcVXlg0bNkeS7aYIIGs8SYOC00S2ALQoL9"
  );

  useEffect(() => {
    createPayementIntent().then((intent) =>
      setClientSecret(intent.clientSecret)
    );
  }, []);

  if (!clientSecret) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ title, cssPath })}
      <div className="billing_wrapper">
        <BackgroundGradientLur />

        <div className="event_summary">
          <h3 className="text_gradient">{event.title}</h3>
          <img
            src={`${api_url}/uploads/events/${event.picture}`}
            alt="Event Thumbnail"
          />
          <div className="event_summary_content">
            <p className="">
              <FontAwesomeIcon icon={faCalendar} />{" "}
              {dayjs(event.date).format("DD/MM/YYYY")}
            </p>
            <p className="">
              <FontAwesomeIcon icon={faLocationDot} />{" "}
              {event.location_formatted}
            </p>
          </div>
        </div>

        <div className="summary_ticket_billing">
          <div className="ticket_summary">
            <img src="/assets/images/qr-code.png"></img>
            <div className="ticket_summary_content">
              <p>{ticket.name}</p>
              <p>Nom Prénom</p>
              <p>{dayjs(ticket.start_date).format("DD/MM/YYYY | hh:mm")}</p>
              <p>{ticket.description}</p>
              <p>Total : {ticket.price.toFixed(2)}€</p>
            </div>
          </div>

          {clientSecret && (
            <Elements
              className="billing_element"
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <CheckoutForm ticket={ticket} clientSecret={clientSecret} />
            </Elements>
          )}
        </div>
      </div>
    </>
  );
}
