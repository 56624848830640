import React from "react";
import ReactDOM from "react-dom/client";
import { Portal } from "react-portal";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { UserProvider } from "./utils/contexts/userContext";
import { SocketProvider } from "./utils/contexts/socketContext";
import { ChatboxProvider } from "./utils/contexts/chatboxContext";
import { EventProvider } from "./utils/contexts/eventContext";
import { MenuProvider } from "./utils/contexts/menuContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = document.getElementById("root");
const rootContainer = ReactDOM.createRoot(root);

// Check if browser supports service workers
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("./utils/contexts/serviceWorker.js")
      .then((registration) => {
        "SW registered: ", registration;
      })
      .catch((registrationError) => {
        "SW registration failed: ", registrationError;
      });
  });
}
rootContainer.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLECLIENTID}>
      <UserProvider>
        <SocketProvider>
          <ChatboxProvider>
            <EventProvider>
              <MenuProvider>
                <App />
                <Portal className="portal"></Portal>
              </MenuProvider>
            </EventProvider>
          </ChatboxProvider>
        </SocketProvider>
      </UserProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);
