import { getApiUrl } from "../contexts/apiContext";

const api_url = getApiUrl();

// Fonction pour récupérer le serveur avec l'id d'event
export async function getEventDiscordServer(eventId) {
  try {
    const response = await fetch(`${api_url}/discord/server/event/${eventId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to get Discord server links");
    }
  } catch (error) {
    console.error(error.message);
  }
}

// Fonction pour créer un lien d'invitation à un serveur discord
export async function createInvite(channelId) {
  try {
    const response = await fetch(`${api_url}/discord/server/invite`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ channelId }),
    });
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      return (window.location = "/login-register?tk=true");
    } else {
      throw new Error("Failed to link Discord account");
    }
  } catch (error) {
    console.error(error.message);
  }
}

// Fonction pour lier un utilisateur Wive à un utilisateur Discord
export async function linkDiscordUser(code, user) {
  try {
    const userId = await user._id;
    const response = await fetch(`${api_url}/discord/link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code, userId }),
    });
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      return (window.location = "/login-register?tk=true");
    } else {
      throw new Error("Failed to link Discord account");
    }
  } catch (error) {
    console.error(error.message);
  }
}

// Fonction pour lier un événement Wive à un serveur Discord
export async function linkDiscordEvent(code, serverId, eventId, user) {
  try {
    const token = await user.token;
    const userId = await user._id;
    const response = await fetch(`${api_url}/discord/link/event`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ code, serverId, eventId, userId }),
    });
    response;
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      //return (window.location = "/login-register?tk=true");
    } else {
      throw new Error("Failed to link Discord account");
    }
  } catch (error) {
    console.error(error.message);
  }
}

// Fonction pour défaire le lien entre un utilisateur Wive et un utilisateur Discord
export async function unlinkDiscordUser(discordUserId, user) {
  try {
    const token = await user.token;
    const response = await fetch(`${api_url}/discord/unlink`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ discordUserId }),
    });

    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      return (window.location = "/login-register?tk=true");
    } else {
      throw new Error("Failed to unlink Discord account");
    }
  } catch (error) {
    console.error(error.message);
  }
}

// Fonction pour récupérer tous les liens associés à un serveur Discord
export async function getDiscordServerLinks(discord_server_id, user) {
  try {
    const token = await user.token;
    const response = await fetch(
      `${api_url}/discord/links/server/${discord_server_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to get Discord server links");
    }
  } catch (error) {
    console.error(error.message);
  }
}
