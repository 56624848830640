import { useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import { translateXMinus30 } from "../../../utils/animations/animations";

export default function RegisterStep3({ user, setUser }) {
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    user.profilepic = file;
    setUser(user);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const animation = translateXMinus30();

  return (
    <>
      <animated.article className="user">
        <div className="left_col">
          {imagePreview ? (
            <div className="image_container">
              <img
                src={imagePreview}
                alt="Profile Preview"
                className="profile_picture"
                id="outputpfp"
                onClick={triggerFileInput}
              />
            </div>
          ) : (
            <img
              src="./assets/images/plus.png"
              alt="Bouton plus"
              className="profile_picture"
              id="outputpfp"
              onClick={triggerFileInput}
              style={{ cursor: "pointer" }}
            />
          )}
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </div>
        <div className="right_col">
          <p>@{user.username}</p>
        </div>
      </animated.article>
      {!user.profilepic && (
        <>
          <p className="profile_pic_txt">Ajouter une photo de profil</p>
        </>
      )}
      <animated.div style={animation} className="form_group">
        <div className="login_input">
          <input
            type="text"
            name="description"
            placeholder="Votre description..."
            onChange={(e) => (user["description"] = e.target.value)}
          />
        </div>
      </animated.div>
      <animated.div style={animation} className="form_group">
        <p className="phone_number">
          Pour améliorer ton expérience, il nous faut ton numéro de téléphone.
        </p>
        <input
          className="login_input"
          type="tel"
          name="phone"
          placeholder="Entrez votre numéro..."
          onChange={(e) => (user["phonenumber"] = e.target.value)}
        />
      </animated.div>
    </>
  );
}
