import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  faCalendar,
  faHome,
  faMap,
  faPlus,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import { isMobile } from "react-device-detect";
import GradientButton from "../Buttons/GradientButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { ChatboxContext } from "../../../utils/contexts/chatboxContext";
import NavHeader from "./tmpl/NavHeader";

const api_url = getImgUrl();

export default function Navbar() {
  const { user } = useUser();
  const isLoggedIn = user && user.email;
  const isHomePage = window.location.pathname === "/";
  const location = useLocation();
  const { setShowNavbar, showNavbar } = useContext(ChatboxContext);
  const [isChatbox, setIsChatbox] = useState(false);
  const [mobileDisplay, setMobileDisplay] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const isSearchPage = location.pathname === "/search";

  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setMobileDisplay(isMobile || window.innerWidth < 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const isOnChatbox =
      location.pathname == "/chatbox" || location.pathname == "/Chatbox";
    setIsChatbox(isOnChatbox);
    if (!isOnChatbox) {
      setShowNavbar(true);
    }
  }, [location.pathname]);
  return (
    <>
      {(mobileDisplay && showNavbar) || !mobileDisplay ? (
        <NavHeader
          isLoggedIn={isLoggedIn}
          mobileDisplay={mobileDisplay}
          isChatbox={isChatbox}
          isSearchPage={isSearchPage}
        />
      ) : null}

      {isLoggedIn && mobileDisplay && !(isChatbox && !showNavbar) && (
        <nav className="mobile_navbar">
          <Link to="/search">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
          </Link>
          <Link to="/events">
            <FontAwesomeIcon icon={faCalendar} className="icon" />
          </Link>
          {!isHomePage ? (
            <Link to="/">
              <FontAwesomeIcon icon={faHome} className="icon" />
            </Link>
          ) : (
            <GradientButton
              icon={faPlus}
              round={true}
              size={"40px"}
              padding={"10px"}
              link={"/create"}
            />
          )}
          <Link to="/map">
            <FontAwesomeIcon icon={faMap} className="icon" />
          </Link>
          <div className="mobile_user_profile">
            <Link to={`/${user.username}`}>
              <img
                src={`${api_url}/uploads/profilepics/${user.profilepic}`}
                alt="User profile"
              />
            </Link>
          </div>
        </nav>
      )}
    </>
  );
}
