import React from "react";

export default function SuggestionsList() {
  return (
    <>
      <SuggestionAccountList />
    </>
  );
}

function SuggestionAccountList() {
  return (
    <>
      <p className="suggestion_text">Quelques suggestions de profils...</p>
      <div className="suggestion_card">
        <p>Coming soon ⏱️</p>
      </div>
    </>
  );
}

function SuggestionEventList() {
  return (
    <>
      <p className="suggestion_text">Quelques suggestions d'évènements...</p>
      <div className="suggestion_card">
        <div className="suggestion_picture">
          <img src="../assets/images/2.jpg" alt="" />
        </div>
        <div>
          <p className="username">@PopPlageAnnecy</p>
          <p className="sub_text">Boite de nuit</p>
        </div>
        <button className="follow_btn">Suivre</button>
      </div>
    </>
  );
}
