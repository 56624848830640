import { animated } from "react-spring";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import updateHead from "../../../utils/helpers/updatePageHead";
import { translateYMinus100 } from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { Link } from "react-router-dom";
import { formatEventTitle } from "../../../utils/helpers/eventHelper";
import { useEffect, useState } from "react";
const api_url = getImgUrl();

export default function EventCard({ event, clearInput }) {
  const cssPath = "/assets/css/components/event_card.css";
  const title = formatEventTitle(event);
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    if (event.organiser_id) {
      setCreator(event.organiser_id);
    }
  }, [event.organiser_id]);
  return (
    <>
      {updateHead({ cssPath })}
      <animated.li
        key={event._id}
        style={translateYMinus100()}
        className="li_event"
      >
        <Link
          to={`/event/${event._id}`}
          className="li_event_container"
          onClick={clearInput}
        >
          <div className="li_event_photo">
            <img
              src={`${api_url}/uploads/events/${event.picture}`}
              alt="current_event_image"
            />
          </div>
          <div className="li_event_event_info">
            <h2>{event.title}</h2>
            <p>{event.organiser_id.username}</p>
            <p>
              {event.date &&
                formatDistanceToNow(new Date(event.date), {
                  addSuffix: true,
                  locale: fr,
                })}
            </p>
            <p>{event.location_formatted}</p>
          </div>
        </Link>
      </animated.li>
    </>
  );
}
