import React from "react";

export default function MainEventInfos({
  eventData,
  setShowEndDate,
  showEndDate,
}) {
  return (
    <>
      <div className="event_card_wrapper">
        <div className="event_card_left_col">
          <input
            type="text"
            name="title"
            defaultValue={eventData.title}
            placeholder="Titre de l'événement"
          />
          <input
            type="text"
            name="event_location"
            defaultValue={eventData.location_formatted}
            placeholder="Lieu de l'événement"
          />
        </div>

        <div className="event_card_right_col">
          <p className="date_txt">Date et heure de l'événement :</p>
          <div className="event_card_flex">
            <input
              type="datetime-local"
              name="date"
              className="date"
              defaultValue={eventData.date}
            />

            {showEndDate && (
              <input
                type="datetime-local"
                name="end_date"
                className="date"
                defaultValue={eventData.end_date}
              />
            )}
          </div>
          <p className="date_btn" onClick={() => setShowEndDate(!showEndDate)}>
            {showEndDate ? "Enlever la date de fin" : "Ajouter une date de fin"}
          </p>
        </div>
      </div>
    </>
  );
}
