import React, { createContext, useState } from "react";

// Créer le contexte
export const ChatboxContext = createContext();

// Créer le fournisseur de contexte
export const ChatboxProvider = ({ children }) => {
  const [activeConv, setActiveConv] = useState(null);
  const [sharedPubli, setSharedPubli] = useState(null);
  const [showNavbar, setShowNavbar] = useState(true);
  const [showConvFirst, setShowConvFirst] = useState(false);

  return (
    <ChatboxContext.Provider
      value={{
        activeConv,
        setActiveConv,
        sharedPubli,
        setSharedPubli,
        showNavbar,
        setShowNavbar,
        showConvFirst,
        setShowConvFirst,
      }}
    >
      {children}
    </ChatboxContext.Provider>
  );
};
