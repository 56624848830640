import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { UserCard } from "../../../Entities/User/UserCard";
import EventCard from "../../../Entities/Event/EventCard";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
import SearchFilters from "./SearchFilters";
import { search } from "../../../../utils/fetchs/searchFetchs";

const api_url = getImgUrl();

export default function SearchInput({
  data,
  setData,
  field,
  filter,
  showGlass = true,
  showFilter = true,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(filter);
  const navigate = useNavigate();
  const resultsRef = useRef(null);
  const inputRef = useRef(null);
  const filterIconsRef = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      // Defines when to show results or not
      if (inputRef.current && inputRef.current.contains(e.target)) {
        // if click on the input, set show
        setIsTyping(true);
      } else if (
        // if click outside of results or input or filters, set hide
        location.pathname !== "/search" &&
        resultsRef.current &&
        !resultsRef.current.contains(e.target) &&
        inputRef.current &&
        !inputRef.current.contains(e.target)
      ) {
        if (
          !data &&
          filterIconsRef.current &&
          filterIconsRef.current !== null &&
          !filterIconsRef.current.contains(e.target)
        ) {
          setIsTyping(false);
        } else setIsTyping(false);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsTyping(true);

    if (value.length > 0) {
      try {
        const data = await search(value);
        setResults(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setResults([]);
      setIsTyping(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsTyping(true);
    navigate(`/search`);
  };

  const clearInput = () => {
    setResults([]);
    setSearchTerm("");
  };
  const handleFilterClick = (filterType) => {
    setCurrentFilter(filterType);
  };

  const handleAddUser = (user) => {
    if (data) {
      setData((prevData) => {
        if (field) {
          return {
            ...prevData,
            [field]: [...(prevData[field] || []), user],
          };
        } else {
          return {
            ...prevData,
            users: [...(prevData.users || []), user],
          };
        }
      });
    }
  };

  const formContent = (
    <>
      <input
        type="text"
        className="search_box"
        placeholder="Rechercher..."
        onChange={handleInputChange}
        value={searchTerm}
        ref={inputRef}
      />
      {showGlass && (
        <FontAwesomeIcon icon={faMagnifyingGlass} className="icon loupe" />
      )}
    </>
  );

  return (
    <section>
      {data ? (
        <div className="search_form">{formContent}</div>
      ) : (
        <form className="search_form" onSubmit={handleSubmit}>
          {formContent}
        </form>
      )}

      {isTyping &&
        (results.users?.length > 0 || results.events?.length > 0) && (
          <div className="search_results" ref={resultsRef}>
            {showFilter && (
              <div ref={filterIconsRef}>
                <SearchFilters
                  currentFilter={currentFilter}
                  handleFilterClick={handleFilterClick}
                />
              </div>
            )}
            {currentFilter !== "event" &&
              results.users?.length > 0 &&
              results.users.map((user) => (
                <div
                  key={user._id}
                  className="result"
                  onClick={() => handleAddUser(user)}
                >
                  <UserCard
                    user={user}
                    link={data ? false : true}
                    clearInput={clearInput}
                  />
                </div>
              ))}
            {currentFilter !== "user" &&
              results.events?.length > 0 &&
              results.events.map((event) => (
                <div key={event.id} className="result">
                  <EventCard event={event} clearInput={clearInput} />
                </div>
              ))}
          </div>
        )}
    </section>
  );
}
