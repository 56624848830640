import React, { useEffect, useState } from "react";
import { animated } from "react-spring";
import {
  translateXMinus100,
  translateYMinus100,
} from "../../../utils/animations/animations";
import { ChatboxSearch } from "./ChatboxSearch";
import { useSocket } from "../../../utils/contexts/socketContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsLeftRightToLine,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { CreaGroupConversation } from "../../../components/Modals/CreaConversation/CreaGroupConv";
import { useUser } from "../../../utils/contexts/userContext";
import { ChatboxConversation } from "./ChatboxConversation";
import GradientButton from "../../../components/Common/Buttons/GradientButton";

export function ChatboxSendersList({
  conversations,
  showChat,
  setShowChat,
  activeConv,
  handleActiveConv,
}) {
  const socket = useSocket();
  const { addConversation, user } = useUser();
  const topAnimation = translateYMinus100();
  const animation = translateXMinus100();
  const [selectedConvList, setSelectedConvList] = useState(conversations);

  const [openConversationModal, setOpenConversationModal] = useState(false);

  typeof handleActiveConv; // Needed to do that for no reason but it makes realise to the script the actual type of the conv

  useEffect(() => {
    socket.emit("JoinConversationsListCanal", "ConversationsList");

    socket.on("newUpdateOnConvList", () => {
      selectedConvList.sort((c1, c2) =>
        c1.lastUpdated > c2.lastUpdated ? -1 : 1
      );
      setSelectedConvList(selectedConvList);
    });

    socket.on("updateConvList", (newConversation) => {
      // Add conv to conv list, at first pos.
      setSelectedConvList((prevConversations) => [
        newConversation,
        ...prevConversations,
      ]);
      addConversation(newConversation._id);
      handleActiveConv(newConversation);
    });

    return () => {
      socket.off("updateConvList");
    };
  }, []);
  return (
    <>
      <animated.div style={topAnimation} className="chatbox_flex">
        <ChatboxSearch
          conversations={conversations}
          handleFilter={setSelectedConvList}
        />
        <CreaGroupConversation
          loggedInUser={user}
          activeConv={activeConv}
          handleActiveConv={handleActiveConv}
          shouldBeOpen={openConversationModal}
          setShouldBeOpen={setOpenConversationModal}
        />
        <GradientButton
          icon={faPlus}
          round={true}
          onClick={() => setOpenConversationModal(true)}
        />
      </animated.div>
      <animated.div style={animation} className="chatbox_list">
        {selectedConvList.map((conversation) => (
          <ChatboxConversation
            key={conversation._id}
            conversation={conversation}
            showChat={showChat}
            setShowChat={setShowChat}
            actualUser={user}
            activeConv={activeConv}
            handleActiveConv={handleActiveConv}
          />
        ))}
      </animated.div>
    </>
  );
}
