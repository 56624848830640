import React from "react";
import { useSpring, animated } from "@react-spring/web";

export default function BouncyVerified() {
  const props = useSpring({
    from: { transform: "rotate(360deg)", width: "50px" },
    to: { transform: "rotate(0deg)", width: "40px" },
    config: { tension: 140, friction: 10, clamp: false },
  });

  return (
    <animated.img
      src="../assets/images/verifier.png"
      alt="verified"
      style={{
        ...props,
        filter: "grayscale(0) blur(0px)",
      }}
      className={"verified"}
    />
  );
}
