import React, { useEffect, useState } from "react";
import TicketSlider from "./TicketSlider";
import ShareEventList from "./ShareEventList";
import MapInner from "../../../components/Entities/Map/MapInner";
import Loader from "../../../components/Common/Loader/Loader";
import { translateXMinus30 } from "../../../utils/animations/animations";
import { useUser } from "../../../utils/contexts/userContext";
import { getTicketsFromEventId } from "../../../utils/fetchs/ticketFetchs";
import { animated } from "react-spring";
import { MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { getConversationByEventId } from "../../../utils/fetchs/conversationFetchs";
import { checkUserParticipation } from "../../../utils/fetchs/saleFetch";
import EventDetails from "./EventDetails";
import EventConv from "./EventConv";
import EventSteps from "./EventSteps";
import { getStepsFromEventId } from "../../../utils/fetchs/stepFetchs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { getDiscordUrl } from "../../../utils/contexts/apiContext";
import EventMap from "./EventMap";

export default function EventInfos({
  event,
  setEvent,
  creator,
  mobileDisplay,
}) {
  const { user } = useUser();
  const [tickets, setTickets] = useState(null);
  const discordUrl = getDiscordUrl();
  const animation = translateXMinus30();
  const [eventConv, setEventConv] = useState(null);

  const [loggedInUserIsParticipating, setLoggedInUserIsParticipating] =
    useState(null);
  const [isCreator, setIsCreator] = useState(
    event.organiser_id === creator._id
  );

  // Check if user participates
  useEffect(() => {
    const checkParticipation = async () => {
      const response = await checkUserParticipation(user, event._id);
      if (response) {
        setLoggedInUserIsParticipating(response.billet[0]);
      }
    };

    checkParticipation();
  }, [event._id, user]);

  // Fetch events tickets
  useEffect(() => {
    getTicketsFromEventId(event._id).then((tickets) => setTickets(tickets));
  }, [user, event._id]);

  // Fetch event conv
  useEffect(() => {
    getConversationByEventId(event._id, user).then((conv) => {
      setEventConv(conv);
    });
  }, [event._id, user]);

  const handleReservationUpdate = (ticket) => {
    // Ajoutez une vérification pour vous assurer que le billet a une structure correcte
    if (ticket && ticket._id) {
      setLoggedInUserIsParticipating(ticket);
    } else {
      console.error("Le billet n'est pas valide :", ticket);
    }
  };

  if (!tickets) {
    return <Loader />;
  }

  return (
    <>
      <animated.div style={animation}>
        <div className="info">
          <h1 className="text_gradient">{event.title}</h1>
          <EventDetails
            event={event}
            setEvent={() => setEvent()}
            creator={creator}
          />
          <div className="hrgradient top" />
          <p className="description">{event.description}</p>
          {event.twitch_channel && event.show_twitch_channel && (
            <div className="twitch-embed">
              <iframe
                src={`https://player.twitch.tv/?channel=${event.twitch_channel}&parent=wive.fr`}
                height="300"
                width="100%"
                allowFullScreen
                frameBorder="0"
                title="Twitch Stream"
              ></iframe>
            </div>
          )}
          <TicketSlider
            event={event}
            tickets={tickets}
            loggedInUserIsParticipating={loggedInUserIsParticipating}
            onReservation={(ticket) => handleReservationUpdate(ticket)}
          />
          {loggedInUserIsParticipating && <EventSteps eventId={event._id} />}
          {event.discord_server_id && !user.discordUserId && (
            <div>
              <p>
                Cet événement est lié à un serveur Discord, pour avoir accès à
                toutes vos étapes, vous devez autoriser Gilbert à examiner votre
                compte Discord.
              </p>
              <a className="discord_btn" href={discordUrl}>
                <FontAwesomeIcon icon={faDiscord} />
                Ouvrir Discord
              </a>
            </div>
          )}
          {mobileDisplay && <EventMap event={event} />}

          {loggedInUserIsParticipating && eventConv && (
            <EventConv eventConv={eventConv} user={user} />
          )}
          {user && user.email && <ShareEventList user={user} />}
          <div className="hrwrapper">
            <p>D'autres événements qui pourraient vous plaire :</p>
            <div className="hrgradient" />
          </div>
          <p>Coming soon ⏱️</p>
        </div>
      </animated.div>
    </>
  );
}
