import React, { useEffect, useState } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import { translateX30 } from "../../utils/animations/animations";
import { useSpring, animated } from "react-spring";
import { useLocation, useParams } from "react-router-dom";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { getImgUrl } from "../../utils/contexts/apiContext";
import Loader from "../../components/Common/Loader/Loader";
import { useUser } from "../../utils/contexts/userContext";
import { getEventById } from "../../utils/fetchs/eventFetchs";
import EventInfos from "./tmpl/EventInfos";
import { getEventDiscordServer } from "../../utils/fetchs/discordFetchs";
const api_url = getImgUrl();
import { isMobile } from "react-device-detect";
import { MapContainer } from "react-leaflet";
import EventMap from "./tmpl/EventMap";

export default function EventPage() {
  const { user } = useUser();
  const cssPath = "/assets/css/pages/event_page.css";
  const [mobileDisplay, setMobileDisplay] = useState(
    isMobile || window.innerWidth < 1100.98
  );
  const [bounds, setBounds] = useState(null);
  const animation = translateX30();
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [creator, setCreator] = useState(null);

  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setMobileDisplay(isMobile || window.innerWidth < 1100.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getEventById(eventId).then((event) => {
      setEvent(event);
      setCreator(event.organiser_id);
    });
  }, [eventId]);

  if (!event || !creator) {
    return <Loader />;
  }
  const title = event.title;
  const position = [
    event.location_coordinates[0],
    event.location_coordinates[1],
  ];
  console.log(mobileDisplay);
  return (
    <>
      {updateHead({ title, cssPath })}
      <section className="main">
        <BackgroundGradientLur />
        <article>
          <div>
            <EventInfos
              event={event}
              setEvent={setEvent}
              creator={creator}
              mobileDisplay={mobileDisplay}
            />
          </div>
          <animated.div style={animation} className="post">
            <img
              src={`${api_url}/uploads/events/${event.picture}`}
              className="post_cover_image"
              alt="publication"
            />
            {!mobileDisplay && <EventMap event={event} />}
          </animated.div>
        </article>
      </section>
    </>
  );
}
