import React, { useEffect, useState } from "react";
import { getEventById } from "../../../../utils/fetchs/eventFetchs";
import { getUserByUserId } from "../../../../utils/fetchs/userFetchs";
import { PublicationHeader } from "../tmpl/PublicationHeader";
import DetailedEventCard from "../tmpl/DetailedEventCard";
import Loader from "../../Loader/Loader";

export function PublicationRepostEvent({ publication, loggedInUser }) {
  const [event, setEvent] = useState(null);
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    getEventById(publication.event_id, loggedInUser).then((event) =>
      setEvent(event)
    );
    getUserByUserId(publication.creator_id).then((user) => setCreator(user));
  }, []);

  if (!event || !creator) {
    return <Loader />;
  }

  return (
    <>
      <li className="post">
        <PublicationHeader creator={creator} />
        <div className="repost_info">
          <p className="description">{publication.content_text}</p>
        </div>
        <DetailedEventCard
          event={event}
          publication={publication}
          creator={creator}
          loggedInUser={loggedInUser}
        />
      </li>
    </>
  );
}
