import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

export async function search(query) {
  try {
    const response = await fetch(`${api_url}/search?query=${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Unable to fetch search results");
    }
  } catch (error) {
    console.error("Error fetching search results:", error);
    throw error;
  }
}
