import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faMessage,
  faUserPlus,
  faCog,
  faUserMinus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { animated } from "react-spring";
import { Link } from "react-router-dom";
import { UserList } from "../../../components/Modals/UserList/UserList";
import {
  createFollowRequest,
  getFollowers,
  getFollowings,
  unfollow,
} from "../../../utils/fetchs/followRequestFetchs";
import { translateYMinus100 } from "../../../utils/animations/animations";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { ChatboxContext } from "../../../utils/contexts/chatboxContext";
import { createConversation } from "../../../utils/fetchs/conversationFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import {
  getCreatedEventsCount,
  getEventsFromParticipantId,
  getParticipatingEventCount,
} from "../../../utils/fetchs/eventFetchs"; // Assurez-vous d'importer les nouvelles méthodes
import { MenuContext } from "../../../utils/contexts/menuContext";

const api_url = getImgUrl();

export default function Header({ profileUser, loggedInUser }) {
  const isCurrentUser = profileUser._id === loggedInUser._id;
  const [openFollowersModal, setOpenFollowersModal] = useState(false);
  const [openFollowingModal, setOpenFollowingModal] = useState(false);
  const [followersList, setFollowersList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const [isFollowingUser, setIsFollowingUser] = useState(false);
  const [createdEventsCount, setCreatedEventsCount] = useState(0);
  const [participatingEventsCount, setParticipatingEventsCount] = useState(0);
  const animation = translateYMinus100();
  const { setActiveConv, setShowConvFirst } = useContext(ChatboxContext);
  const { setMenuOpen } = useContext(MenuContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFollowers = async () => {
      const followers = await getFollowers(loggedInUser, profileUser._id);
      setFollowersList(followers);
      setIsFollowingUser(
        followers.some((follower) => follower._id === loggedInUser._id)
      );
    };

    const fetchFollowings = async () => {
      const followings = await getFollowings(loggedInUser, profileUser._id);
      setFollowingList(followings);
    };

    fetchFollowers();
    fetchFollowings();
  }, [profileUser._id]);

  useEffect(() => {
    const fetchEvents = async () => {
      const createdEvents = await getCreatedEventsCount(profileUser._id);
      setCreatedEventsCount(createdEvents || 0);

      const participatingEvents = await getParticipatingEventCount(
        profileUser._id
      );
      setParticipatingEventsCount(participatingEvents || 0);
    };

    fetchEvents();
  }, [profileUser._id]);

  const handleFollow = async (user) => {
    const followExist = await unfollow(loggedInUser, user._id);
    if (followExist) {
      // If follow exists then we delete it from local list
      setFollowingList(
        followingList.filter((following) => following._id !== user._id)
      );
      if (profileUser._id === user._id) {
        setIsFollowingUser(false);
      }
    } else {
      const response = await createFollowRequest(loggedInUser, user._id);
      if (response) {
        setFollowersList([...followersList, loggedInUser]);
        setIsFollowingUser(true);
      }
    }
  };

  const sendMessage = async () => {
    let conv = {
      type: "private",
      participants: [loggedInUser._id, profileUser._id],
    };
    const savedConv = await createConversation(loggedInUser, conv);
    if (
      savedConv &&
      savedConv.success === false &&
      savedConv.existingConversation
    ) {
      setActiveConv(savedConv.existingConversation.conversation);
    } else {
      setActiveConv(savedConv.conversation);
    }
    setShowConvFirst(true);
    navigate("/chatbox");
  };

  const handleOpenSettings = () => {
    setMenuOpen(true);
    navigate("/account");
  };

  return (
    <animated.header style={animation} className="profile_container">
      <div className="profile">
        <div className="profile_image">
          <img
            src={`${api_url}/uploads/profilepics/${profileUser.profilepic}`}
            alt="User profile"
          />
        </div>
        <div className="profile_user_settings">
          <h1 className="profile_user_name">@{profileUser.username}</h1>
          {profileUser.role === "founder" && !isCurrentUser && (
            <img src="/assets/images/verifier.png" />
          )}
          {isCurrentUser && (
            <FontAwesomeIcon
              icon={faCog}
              className="icon"
              onClick={handleOpenSettings}
            />
          )}
        </div>
        <div className="profile_stats">
          <ul>
            <li>
              <FontAwesomeIcon icon={faCalendar} className="icon" />
              <span className="profile_stats_count">
                {createdEventsCount + participatingEventsCount}
              </span>
              événements
            </li>
            <li>
              <UserList
                title="Followers"
                userList={followersList}
                shouldBeOpen={openFollowersModal}
                setShouldBeOpen={setOpenFollowersModal}
              />
              <span
                className="profile_stats_count"
                onClick={() => setOpenFollowersModal(true)}
              >
                {followersList.length} abonnés
              </span>
            </li>
            <li>
              <UserList
                title="Following"
                userList={followingList}
                shouldBeOpen={openFollowingModal}
                setShouldBeOpen={setOpenFollowingModal}
                buttonAction={isCurrentUser ? handleFollow : null}
              />

              <span
                className="profile_stats_count"
                onClick={() => setOpenFollowingModal(true)}
              >
                {followingList.length} abonnements
              </span>
            </li>
          </ul>
        </div>
        {!isCurrentUser && (
          <div className="profile_btn">
            <GradientButton
              icon={isFollowingUser ? faUserMinus : faUserPlus}
              text={isFollowingUser ? "Ne plus suivre" : "Suivre"}
              onClick={() => handleFollow(profileUser)}
            />

            <GradientButton
              icon={faMessage}
              text={"Message"}
              onClick={() => sendMessage()}
            />
          </div>
        )}
        <div className="profile_bio">
          <p>
            <span className="profile_real_name">
              {profileUser.firstname} {profileUser.lastname}
            </span>
            <br></br>
            {profileUser.description}
          </p>
        </div>
      </div>
    </animated.header>
  );
}
