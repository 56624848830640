import React from "react";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";

export function ChatboxSearch({ conversations, handleFilter }) {
  const { user } = useUser();

  const handleSearch = (value) => {
    handleFilter(
      conversations.filter((conv) =>
        conv.participants.some((e) => e.username.startsWith(value) && e.username !== user.username)
      )
    );
  };

  const animation = useSpring({
    from: { opacity: 0, transform: "translateY(-100%)" },
    to: { opacity: 1, transform: "translateY(0%)" },
  });
  
  return (
    <>
      {/* Recherche */}
      <div className="chatbox_search_chat">
        <input
          type="text"
          placeholder="Rechercher une conversation"
          onChange={(e) => handleSearch(e.target.value)}/>
        <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
      </div>
    </>
  );
}
