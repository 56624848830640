import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext";

export function LogoutModalContent({ closeModal }) {
  const navigate = useNavigate();
  const { logout } = useUser();

  const logoutUser = () => {
    logout();
    navigate("/presentation");
  };

  return (
    <>
      <p>Êtes vous sûr de vouloir vous déconnecter ?</p>
      <button className="logout_yes" onClick={logoutUser}>
        oui <FontAwesomeIcon icon={faRightFromBracket} />
      </button>
      <button className="logout_no" onClick={closeModal}>
        non <FontAwesomeIcon icon={faXmark} />
      </button>
    </>
  );
}
