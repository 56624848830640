import React, { useState, useEffect } from "react";
import { useUser } from "../../../utils/contexts/userContext";
import { SpinningLoader } from "../../Common/Loader/SpinningLoader";
import SingleUserPicture from "../User/SingleUserPicture";
import { getFollowings } from "../../../utils/fetchs/followRequestFetchs";

export function ParticipatingFriendList({ participants }) {
  const { user } = useUser();
  const isLoggedIn = user && user.email;
  const [friendsList, setfriendsList] = useState();

  useEffect(() => {
    if (isLoggedIn) {
      const fetchFollowings = async () => {
        const followings = await getFollowings(user, user._id);
        const participatingFriends = followings.filter((friend) =>
          participants
            .map((participant) => participant._id)
            .includes(friend._id)
        );
        setfriendsList(participatingFriends);
      };
      fetchFollowings();
    }
  }, [user._id, participants]);

  if (!friendsList) {
    return <SpinningLoader />;
  }

  return (
    <>
      <div className="list_wrap">
        {!isLoggedIn && (
          <p className="list_count_text_placeholder">
            ☝️ Créez vous un compte Wive pour y participer
          </p>
        )}
        {isLoggedIn && friendsList.length > 0 ? (
          <>
            <ul className="friends_list">
              {friendsList
                .filter((_, i) => i < 3)
                .map((friend) => (
                  <li key={friend._id} className="friend_card">
                    <SingleUserPicture user={friend} />
                  </li>
                ))}
            </ul>
            {friendsList.length <= 3 ? (
              <p className="list_count_text">
                {friendsList.length !== 1 ? `participent` : `participe`} à cet
                événement.
              </p>
            ) : (
              <p className="list_count_text">
                et {friendsList.length - 3} autres amis y participent.
              </p>
            )}
          </>
        ) : (
          <p className="list_count_text_placeholder">
            ☝️ Soyez le premier de vos amis à y participer
          </p>
        )}
      </div>
    </>
  );
}
