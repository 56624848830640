import React, { useEffect, useState } from "react";
import { MapContainer } from "react-leaflet";
import updateHead from "../../../utils/helpers/updatePageHead";
import MapInner from "../../../components/Entities/Map/MapInner";
import { useUser } from "../../../utils/contexts/userContext";
import { getEventDiscordServer } from "../../../utils/fetchs/discordFetchs";
import Loader from "../../../components/Common/Loader/Loader";
import { validateStepAccess } from "../../../utils/fetchs/stepFetchs";

export default function EventSteps({ eventId }) {
  const cssPath = "/assets/css/components/steps_display.css";
  const { user } = useUser();
  const [discordServer, setDiscordServer] = useState(null);
  const [steps, setSteps] = useState(null);
  const [bounds, setBounds] = useState(null);

  // Fetch Discord server info
  useEffect(() => {
    const fetchDiscordInfo = async () => {
      if (!user) return;
      try {
        const serverData = await getEventDiscordServer(eventId);
        setDiscordServer(serverData);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des infos Discord:",
          error
        );
      }
    };
    fetchDiscordInfo();
  }, [eventId, user]);

  // Fetch accessible steps
  useEffect(() => {
    const fetchAccessibleSteps = async () => {
      if (!user || !discordServer) return;
      try {
        const accessResponse = await validateStepAccess(
          user,
          eventId,
          discordServer.id
        );
        setSteps(accessResponse.accessibleSteps || []);
      } catch (error) {
        console.error(
          "Erreur lors de la validation d'accès aux étapes:",
          error
        );
      }
    };
    fetchAccessibleSteps();
  }, [discordServer, eventId, user]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const renderPoll = (poll, pollIndex) => (
    <div key={pollIndex} className="step_poll">
      <h4>{poll.title}</h4>
      <p>{poll.description}</p>
      <div className="poll_choices">
        {poll.choices.map((choice, choiceIndex) => (
          <div key={choiceIndex} className="poll_choice">
            <span>{choice.text}</span>
            <span>{choice.votes.length} votes</span>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSubStep = (step, index) => (
    <div key={step._id || index} className="step_card sub_step">
      <div className="step_header">
        <h4>{step.title}</h4>
        {step.date && (
          <span className="step_date">{formatDate(step.date)}</span>
        )}
      </div>

      {step.content_picture && (
        <img
          src={step.content_picture}
          alt={step.title}
          className="step_image"
        />
      )}
      {step.content && <p className="step_content">{step.content}</p>}
      {step.location && <p className="step_location">📍 {step.location}</p>}
      {step.polls?.map((poll, pollIndex) => renderPoll(poll, pollIndex))}
    </div>
  );

  const renderMainStep = (mainStep, index) => (
    <div key={mainStep._id || index} className="main_step_container">
      <h3 className="text_gradient">{mainStep.title}</h3>

      {/* Render main step content if it exists */}
      {(mainStep.content ||
        mainStep.content_picture ||
        mainStep.location ||
        mainStep.polls) && (
        <div className="main_step_content">
          {mainStep.content_picture && (
            <img
              src={mainStep.content_picture}
              alt={mainStep.title}
              className="step_image"
            />
          )}
          {mainStep.content && (
            <p className="step_content">{mainStep.content}</p>
          )}
          {mainStep.location && (
            <p className="step_location">📍 {mainStep.location}</p>
          )}
          {mainStep.polls?.map((poll, pollIndex) =>
            renderPoll(poll, pollIndex)
          )}
        </div>
      )}

      {/* Render sub steps */}
      {mainStep.steps && mainStep.steps.length > 0 && (
        <div className="sub_steps_container">
          {mainStep.steps.map((subStep, subIndex) =>
            renderSubStep(subStep, subIndex)
          )}
        </div>
      )}
    </div>
  );

  if (!steps) {
    return <Loader />;
  }

  // Récupérer toutes les étapes avec une location (principales et sous-étapes)
  const getAllStepsWithLocation = (steps) => {
    return steps.reduce((acc, mainStep) => {
      const stepsWithLocation = [];
      if (mainStep.location) {
        stepsWithLocation.push(mainStep);
      }
      if (mainStep.steps) {
        stepsWithLocation.push(
          ...mainStep.steps.filter((subStep) => subStep.location)
        );
      }
      return [...acc, ...stepsWithLocation];
    }, []);
  };

  const stepsWithLocation = getAllStepsWithLocation(steps);
  const position = stepsWithLocation[0] && [
    stepsWithLocation[0].location_coordinates[0],
    stepsWithLocation[0].location_coordinates[1],
  ];
  return (
    <>
      {updateHead({ cssPath })}
      {stepsWithLocation.length > 0 && (
        <div className="map_container">
          <MapContainer
            center={position}
            zoom={13}
            style={{ height: "300px", width: "100%" }}
          >
            <MapInner events={stepsWithLocation} handleBounds={setBounds} />
          </MapContainer>
        </div>
      )}

      <div className="steps_timeline">
        {steps.map((mainStep, index) => renderMainStep(mainStep, index))}
      </div>
    </>
  );
}
