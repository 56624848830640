import React, { useEffect, useState } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import OrganizedEvents from "../Personnal Events/tmpl/OrganizedEvents";
import { useUser } from "../../utils/contexts/userContext";
import { getEventsFromUserId } from "../../utils/fetchs/eventFetchs";
import Loader from "../../components/Common/Loader/Loader";
import {
  linkDiscordEvent,
  linkDiscordUser,
} from "../../utils/fetchs/discordFetchs";
import { UserCard } from "../../components/Entities/User/UserCard";
import GradientButton from "../../components/Common/Buttons/GradientButton";
import BouncyVerified from "./BouncyVerified";
import { useNavigate } from "react-router-dom";

export default function Discord({ event }) {
  const cssPath = "/assets/css/pages/discord.css";
  const title = "Wive | Lier à Discord";

  const [events, setEvents] = useState(null);
  const [link, setLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user, login } = useUser();
  const queryParams = new URLSearchParams(location.search);

  const discordCode = queryParams.get("code");
  const discord_server_id = queryParams.get("guild_id");

  useEffect(() => {
    if (user && user._id) {
      setIsLoading(true); // Démarrez le chargement
      getEventsFromUserId(user).then((events) => {
        setEvents(events);
        setIsLoading(false); // Arrêtez le chargement
      });
    }
  }, [user, user._id]);

  useEffect(() => {
    const linkAccounts = async () => {
      if (!discord_server_id && discordCode) {
        try {
          setIsLoading(true);
          const newLink = await linkDiscordUser(discordCode, user);
          if (newLink.token) {
            login(newLink.user, newLink.token);
            return navigate("/");
          }
          setIsLoading(false);
        } catch (error) {
          console.error("Failed to link accounts: ", error);
          setIsLoading(false);
        }
      }
    };

    linkAccounts();
  }, [discordCode, discord_server_id, user._id]);

  const linkEvent = async (eventId) => {
    if (discord_server_id && user._id) {
      try {
        setIsLoading(true);
        const newLink = await linkDiscordEvent(
          discordCode,
          discord_server_id,
          eventId,
          user
        );
        newLink;
        setLink(newLink);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to link accounts: ", error);
        setIsLoading(false);
      }
    }
  };

  const openDiscord = () => {
    if (discord_server_id) {
      // Ouvrir le serveur Discord dans un nouvel onglet
      window.open(`https://discord.gg/${discord_server_id}`, "_blank");
    } else if (discordCode) {
      // Ouvrir l'application Discord
      window.open("https://discord.com/app", "_blank");
    } else {
      window.open(
        "https://discord.com/oauth2/authorize?client_id=1293142580397211648&permissions=8&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&integration_type=0&scope=identify+connections+guilds+guilds.join+guilds.members.read+bot",
        "_blank"
      );
    }
  };

  return (
    <>
      {updateHead({ title, cssPath })}
      <div className="form_content">
        <div className="gilbert_message">
          <UserCard user={"gilbert"} />
          <p>Gilbert communique avec Discord...</p>
        </div>
        {isLoading && <Loader />}
      </div>
      {discordCode && link && (
        <div className="form_content">
          <div className="flex_center">
            <UserCard user={user} />
            <BouncyVerified />
          </div>
          <p>Vos comptes sont liés.</p>
          {!discord_server_id && (
            <GradientButton
              text={"Retourner sur Discord"}
              onClick={openDiscord}
            />
          )}
        </div>
      )}
      {discord_server_id && events && (
        <div className="event_form_content">
          <p>Selectionnez un événement à lier à ce serveur :</p>
          <OrganizedEvents events={events} linkEvent={linkEvent} />
        </div>
      )}
      {!discordCode && !discord_server_id && (
        <div className="form_content">
          <GradientButton text={"Lier Wive et Discord"} onClick={openDiscord} />
        </div>
      )}
    </>
  );
}
