import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompletionCircle from "../../../pages/EventStudio/tmpl/CompletionCircle";
import { Link } from "react-router-dom";
import { MenuContext } from "../../../utils/contexts/menuContext";
import { isMobile } from "react-device-detect";

export default function MenuItem({
  icon,
  label,
  percentage,
  onClick,
  applyOffset = false,
}) {
  const { menuOpen, setMenuOpen } = useContext(MenuContext);
  const [isPhone, setIsPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );

  // Function to handle scroll behavior when menu item is clicked
  const handleClick = () => {
    if (isPhone && menuOpen) {
      setMenuOpen(false);
    }
    if (typeof onClick === "function") {
      onClick(applyOffset);
    }
  };
  const menuContent = (
    <>
      <FontAwesomeIcon icon={icon} className="icon" />
      <p>{label}</p>
    </>
  );
  return (
    <div className="menu_container">
      {typeof onClick === "string" ? (
        <Link to={onClick} className="menu">
          {menuContent}
        </Link>
      ) : (
        <div className="menu" onClick={handleClick}>
          {menuContent}
        </div>
      )}
      {percentage !== undefined && <CompletionCircle percentage={percentage} />}
    </div>
  );
}
