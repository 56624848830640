import React, { useRef, useState } from "react";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function VerifProfile({ user }) {
  const [imagePreviewv1, setImagePreviewv1] = useState(null);
  const [imagePreviewv2, setImagePreviewv2] = useState(null);
  const fileInputRef = useRef(null);

  const checkProfile = () => {
    // send file to API age check
    // retreive response
    // set field in DB
    // display result message
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        imagePreviewv1
          ? setImagePreviewv2(reader.result)
          : setImagePreviewv1(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <section className="verif_profile">
        <h2>Vérification du profile</h2>
        <article className="user">
          <div className="second_left_col">
            <img
              src={`${api_url}/uploads/profilepics/${user.profilepic}`}
              alt="profile_picture"
            />
          </div>
          <div className="second_right_col">
            <p>@{user.username}</p>
          </div>
        </article>
        <p>
          Vérifier votre profile afin de pouvoir acheter des billets pour des
          évènements ou un age minimal est requis.
        </p>
        <article className="id_card_container">
          <h5>Face avant :</h5>
          <div className="image_container">
            {imagePreviewv1 ? (
              <img
                src={imagePreviewv1}
                alt="id card Thumbnail"
                className="id_card_picture"
                onClick={triggerFileInput}
              />
            ) : (
              <img
                src="./assets/images/plus.png"
                alt="id card Thumbnail"
                className="id_card_picture"
                onClick={triggerFileInput}
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </article>
        <article className="id_card_container">
          <h5>Face arrière : </h5>
          <div className="image_container">
            {imagePreviewv2 ? (
              <img
                src={imagePreviewv2}
                alt="id card Thumbnail"
                className="id_card_picture"
                onClick={triggerFileInput}
              />
            ) : (
              <img
                src="./assets/images/plus.png"
                alt="id card Thumbnail"
                className="id_card_picture"
                onClick={triggerFileInput}
              />
            )}
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </article>
        <GradientButton type={"save"} onClick={() => checkProfile()} />
      </section>
    </>
  );
}
