import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper/core";
import { Navigation, Pagination } from "swiper/modules";
import { TicketSlide } from "./TicketSlide";
import "swiper/swiper-bundle.css";
import updateHead from "../../../utils/helpers/updatePageHead";
import { UserCard } from "../../../components/Entities/User/UserCard";
import { useUser } from "../../../utils/contexts/userContext";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import DiscordServerCard from "../../EventStudio/tmpl/DiscordServerCard";
import { getEventDiscordServer } from "../../../utils/fetchs/discordFetchs";
SwiperCore.use([Navigation, Pagination]);

export default function TicketSlider({
  tickets,
  event,
  showTitle = true,
  loggedInUserIsParticipating,
  onReservation,
}) {
  const { user } = useUser();
  const cssPath = "/assets/css/components/ticket_slider.css";
  const [discordServer, setDiscordServer] = useState(null);

  // Fetch ticket guild
  useEffect(() => {
    if (event && event._id) {
      getEventDiscordServer(event._id).then((guild) => {
        setDiscordServer(guild);
      });
    }
  }, [event]);
  return (
    <>
      {updateHead({ cssPath })}

      {loggedInUserIsParticipating ? (
        <div className="ticket_box">
          <p className="text_gradient">Votre billet :</p>
          <div className="flex_center">
            <UserCard user={user} subText={loggedInUserIsParticipating.name} />
            <p className="ticket_descirption">
              {loggedInUserIsParticipating.description}
            </p>
          </div>
          {event && event.generate_discord_link === "participants" && (
            <div>
              <p>
                Ce billet vous donne accès au serveur discord de l'événement :
              </p>
              {discordServer && (
                <DiscordServerCard discordServer={discordServer} />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="ticket_store">
          {showTitle ? (
            <div className="hrwrapper">
              <p>Billets :</p>
              <div className="hrgradient"></div>
            </div>
          ) : null}

          {tickets.length ? (
            <Swiper
              spaceBetween={40}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              mousewheel={true}
            >
              {tickets.map((ticket) => (
                <SwiperSlide key={ticket._id} style={{ width: "200px" }}>
                  <TicketSlide
                    ticket={ticket}
                    onReservation={(ticket) => onReservation(ticket)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p>Il n'y a pas encore de billets disponible pour cet événement</p>
          )}
        </div>
      )}
    </>
  );
}
