import { animated } from "react-spring";
import { translateXMinus30 } from "../../../utils/animations/animations";

export default function RegisterStep1({ user, setUser }) {
  const animation = translateXMinus30()

  return (
    <>
      <animated.div style={animation} className="form_group">
        <div className="login_input">
          <input
            type="text"
            name="email"
            placeholder="Votre adresse mail ou numéro de téléphone..."
            onChange={(e) => (user["email"] = e.target.value)}
          />
        </div>
      </animated.div>
      <animated.div style={animation} className="form_group">
        <div className="login_input">
          <input
            type="password"
            name="password"
            placeholder="Votre mot de passe..."
            onChange={(e) => (user["password"] = e.target.value)}
          />
        </div>
      </animated.div>
      <animated.div style={animation} className="form_group">
        <div className="login_input">
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirmez votre mot de passe..."
            onChange={(e) => (user["confirmPassword"] = e.target.value)}
          />
        </div>
      </animated.div>
    </>
  );
}