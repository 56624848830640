import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

export async function uploadTicketImage(imageFile) {
  const sendData = new FormData();
  sendData.append("billetpic", imageFile);

  const response = await fetch(`${api_url}/upload/billetpic`, {
    method: "POST",
    body: sendData,
  });

  switch (response.status) {
    case 200:
      return await response.json();
    default:
      throw new Error("Unable to upload event picture");
  }
}

export async function uploadEventPicture(imageFile) {
  const sendData = new FormData();
  sendData.append("picture", imageFile);

  const response = await fetch(`${api_url}/upload/picture`, {
    method: "POST",
    body: sendData,
  });

  switch (response.status) {
    case 200:
      return await response.json();
    default:
      throw new Error("Unable to upload event picture");
  }
}

export async function uploadProfilePicture(file) {
  try {
    const sendData = new FormData();
    sendData.append("profilepic", file);

    const response = await fetch(`${api_url}/upload/profilepic`, {
      method: "POST",
      body: sendData,
    });

    switch (response.status) {
      case 200:
        let url = await response.json();
        return url;
      default:
        throw new Error("Unable to upload profile picture");
    }
  } catch (error) {
    console.error(error.message);
  }
}
