import React from "react";
import SingleUserPicture from "../../../components/Entities/User/SingleUserPicture";
import { Link } from "react-router-dom";
import { LogoutModal } from "../../../components/Modals/LogoutModal/LogoutModal";
import { useUser } from "../../../utils/contexts/userContext";

export default function ProfileCard() {
  const { user } = useUser();

  return (
    <>
      {/* Profil */}
      {user && user.email && (
        <div className="profile_card">
          <Link to={`/${user.username}`}>
            <SingleUserPicture user={user} />
          </Link>
          <Link to={`/${user.username}`}>
            <div>
              <p className="username">@{user.username}</p>
              <p className="sub_text">
                {user.firstname} {user.lastname}
              </p>
            </div>
          </Link>

          <button className="input_btn">
            <LogoutModal />
          </button>
        </div>
      )}
    </>
  );
}
