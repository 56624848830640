import React, { useEffect, useState } from "react";
import { UserCard } from "../../components/Entities/User/UserCard";
import { getUserByUserId } from "../../utils/fetchs/userFetchs";
import Loader from "../../components/Common/Loader/Loader";
import updateHead from "../../utils/helpers/updatePageHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faPaperPlane,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { getImgUrl } from "../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function Partnership() {
  const [boss, setBoss] = useState(null);
  const [gilbert, setGilbert] = useState(null);
  const [messages, setMessages] = useState([]); // Gestion des messages
  const [newMessage, setNewMessage] = useState(""); // Input for new message

  const bossId = "653189026fc3e182fb0cc01a"; // ID de Martin
  const gilbertId = "66820b331c49476d9e90868d"; // ID de Gilbert
  const cssPath = "/assets/css/pages/partnership.css";
  const title = "Wive | Partenariats";

  useEffect(() => {
    // Fonction pour récupérer les informations de Martin et Gilbert
    const fetchUsers = async () => {
      try {
        const [bossData, gilbertData] = await Promise.all([
          getUserByUserId(bossId),
          getUserByUserId(gilbertId),
        ]);
        setBoss(bossData);
        setGilbert(gilbertData);
      } catch (error) {
        console.error("Erreur lors du fetch des utilisateurs :", error);
      }
    };

    fetchUsers(); // Appel de la fonction dès le montage du composant
  }, []);

  const handleCopyEmail = () => {
    navigator.clipboard
      .writeText("martin@wive.fr")
      .then(() => alert("E-mail copié dans le presse-papiers !"))
      .catch((error) => console.error("Erreur lors de la copie :", error));
  };
  return (
    <>
      {updateHead({ title, cssPath })}
      {boss && gilbert ? (
        <>
          <UserCard user={boss} />
          <p className="text">
            Prenez directement contact avec moi par mail ou au téléphone :
          </p>
          <div className="text_container">
            <div className="text_box">
              <FontAwesomeIcon icon={faEnvelope} />
              <p>martin@wive.fr</p>
              <FontAwesomeIcon icon={faCopy} onClick={handleCopyEmail} />
            </div>
            <div className="text_box">
              <FontAwesomeIcon icon={faPhone} />
              <a href="tel:+33695312109">06.95.31.21.09</a>{" "}
              {/* Lien pour appeler */}
            </div>
            <div className="text_box">
              <FontAwesomeIcon icon={faPaperPlane} />
              <a href="sms:+33695312109">SMS</a>{" "}
              {/* Lien pour envoyer un SMS */}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
