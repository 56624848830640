import React, { createContext, useContext, useState, useEffect } from "react";

// Création du contexte
const EventContext = createContext();

// Hook pour utiliser le contexte
export const useEvent = () => {
  return useContext(EventContext);
};

// Provider pour envelopper l'application
export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState({
    _id: "",
    title: "",
    organiser_id: "",
    creation_date: null,
    picture: "",
    date: "",
    end_date: "",
    location_formatted: "",
    location_coordinates: [0, 0],
    description: "",
    guest_list: [],
    private: true,
    adults_only: false,
    recurrent: false,
    program_publi_date: null,
    custom_dates: [],
    event_conversation: true,
  });

  // Fonction pour créer un événement
  const createEvent = (newEvent) => {
    setEvent(newEvent);
    localStorage.setItem("event", JSON.stringify(newEvent));
  };

  // Fonction pour mettre à jour un événement
  const updateEvent = (updatedEvent) => {
    setEvent(updatedEvent);
    localStorage.setItem("event", JSON.stringify(updatedEvent));
  };

  // Récupérer un événement à partir du localStorage au montage
  useEffect(() => {
    const storedEvent = localStorage.getItem("event");
    if (storedEvent) {
      try {
        const parsedEvent = JSON.parse(storedEvent);
        setEvent(parsedEvent);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'événement depuis le localStorage :",
          error
        );
      }
    }
  }, []);

  // Réinitialiser l'événement (en cas de suppression ou autre)
  const resetEvent = () => {
    setEvent({
      _id: "",
      title: "",
      organiser_id: "",
      creation_date: null,
      picture: "",
      date: "",
      end_date: "",
      location_formatted: "",
      location_coordinates: [0, 0],
      description: "",
      guest_list: [],
      private: true,
      adults_only: false,
      recurrent: false,
      program_publi_date: null,
      custom_dates: [],
      event_conversation: true,
    });
    localStorage.removeItem("event");
  };

  const value = {
    event,
    setEvent,
    createEvent,
    updateEvent,
    resetEvent,
  };

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};
