export async function retreiveLocation (location) {
    // call open cage api in order to retrieve location
    try {
      const response = await fetch(
        "https://api.opencagedata.com/geocode/v1/json?q=" +
          location +
          "&key=2c3c3105180444ae9a3ee0b0e19f4740",
        {
          method: "GET",
        }
      );

      const result = await response.json();

      if (result) {
        const data = result.results[0];
        if (data) {
          const { lat, lng } = data.geometry;
          const loc = data.formatted;
          return {loc, lat, lng};
        } else {
          // Handle error: address not found
          return {loc:"", lat: 0, lng: 0};
        }
      }


    } catch (error) {
      console.error(error);
    }

  };