import React, { useEffect, useState } from "react";
import updateHead from "../../../utils/helpers/updatePageHead";
import { animated, useSpring } from "react-spring";
import { Portal } from "react-portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { createConversation } from "../../../utils/fetchs/conversationFetchs";
import { useSocket } from "../../../utils/contexts/socketContext";
import GradientButton from "../../Common/Buttons/GradientButton";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { getFollowings } from "../../../utils/fetchs/followRequestFetchs";
const api_url = getImgUrl();

export function CreaGroupConversation({
  loggedInUser,
  activeConv,
  handleActiveConv,
  shouldBeOpen,
  setShouldBeOpen,
}) {
  const [followingUsers, setFollowingUsers] = useState(null);
  const [followingUsersFiltered, setFollowingUsersFiltered] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [conversation, setConversation] = useState({
    participants: [loggedInUser._id],
  });
  const socket = useSocket();
  const cssPath = "/assets/css/components/crea_conv.css";

  const modalTransition = useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });

  const opacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  const resetConversation = () => {
    setConversation({
      participants: [loggedInUser._id],
      // Ajoutez d'autres champs initiaux si nécessaire
    });
  };

  const handleConvChange = (name, value) => {
    setConversation((prevConversation) => {
      // Check if the value already exists in the participants array
      const alreadyIncluded = prevConversation[name].includes(value);
      let updatedParticipants;

      if (alreadyIncluded) {
        // If included, remove it
        updatedParticipants = prevConversation[name].filter(
          (id) => id !== value
        );
      } else {
        // If not included, add it
        updatedParticipants = [...prevConversation[name], value];
      }

      // Determine the type of conversation based on the number of participants
      const updatedType = updatedParticipants.length > 2 ? "group" : "private";

      return {
        ...prevConversation,
        [name]: updatedParticipants,
        type: updatedType,
      };
    });
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const handleSearch = (value) => {
    setFollowingUsersFiltered(
      followingUsers.filter((user) => user.username.startsWith(value))
    );
  };

  useEffect(() => {
    const setFollowing = async () => {
      const followings = await getFollowings(loggedInUser, loggedInUser._id);

      setFollowingUsers(followings);
      setFollowingUsersFiltered(followings);
    };

    if (shouldBeOpen && !followingUsers) {
      setFollowing();
    }

    if (shouldBeOpen && followingUsers) {
      openModal();
    }
  }, [shouldBeOpen, followingUsers, loggedInUser.following]);

  const closeModal = () => {
    resetConversation();
    setModalIsOpen(false);
    setShouldBeOpen(false);
  };

  const handleCreateConversation = async () => {
    const result = await createConversation(loggedInUser, conversation);
    if (result && result.success) {
      socket.emit("newConversation", result.conversation);
    } else if (result && !result.success) {
      handleActiveConv(result.existingConversation.conversation);
    }
    resetConversation();
    closeModal();
  };
  if (!modalIsOpen) {
    return <></>;
  }

  return (
    <>
      {updateHead({ cssPath })}
      {modalIsOpen && (
        <Portal>
          <animated.div
            className="modal_backdrop"
            style={opacityTransition}
            onClick={closeModal}
          ></animated.div>
          <animated.div className="modal_box" style={modalTransition}>
            <div className="modal_conv_title_container">
              <h2>Créer une conversation</h2>
              <input
                className="follow_search"
                type="text"
                placeholder="Rechercher un ami"
                onChange={(e) => handleSearch(e.target.value)}
              ></input>
            </div>
            <ul className="modal_follow_list">
              {followingUsersFiltered.map((f, index) => (
                <li key={f._id}>
                  <div className="chatbox_flex">
                    <img
                      className="pp_follow"
                      src={`${api_url}/uploads/profilepics/${f.profilepic}`}
                      alt="follower profile"
                    />
                    <div className="user_infos">
                      <p>@{f.username}</p>
                      <p>&emsp;{f.description}</p>
                    </div>
                  </div>
                  {conversation.participants.includes(f._id) ? (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="icon_remove_user"
                      onClick={() => handleConvChange("participants", f._id)}
                    />
                  ) : (
                    <GradientButton
                      icon={faPlus}
                      round={true}
                      onClick={() => handleConvChange("participants", f._id)}
                    />
                  )}
                </li>
              ))}
            </ul>
            <GradientButton
              icon={faMessage}
              text={"Nouvelle conversation"}
              padding={"30px"}
              onClick={handleCreateConversation}
            />
          </animated.div>
        </Portal>
      )}
    </>
  );
}
