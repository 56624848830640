import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faMicrophone,
  faCamera,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { animated } from "react-spring";
import { translateY100 } from "../../../utils/animations/animations";
import { useSocket } from "../../../utils/contexts/socketContext";
import { sendMessage } from "../../../utils/fetchs/messagesFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import { getEventById } from "../../../utils/fetchs/eventFetchs";
import DetailedEventCard from "../../../components/Common/Feed/tmpl/DetailedEventCard";
import { getUserByUserId } from "../../../utils/fetchs/userFetchs";
import Loader from "../../../components/Common/Loader/Loader";

export function ChatboxChatInput({
  activeConv,
  actualUser,
  sharedPubli,
  setSharedPubli,
}) {
  const socket = useSocket();
  const { user } = useUser();
  const [event, setEvent] = useState(null);
  const [creator, setCreator] = useState(null);
  const animation = translateY100();
  const [msg, setMsg] = useState({
    conversationId: "",
    senderId: actualUser,
    content: "",
    timestamp: null,
    read: false,
  });
  useEffect(() => {
    if (sharedPubli) {
      getEventById(sharedPubli.event_id, user).then((event) => setEvent(event));
      getUserByUserId(sharedPubli.creator_id).then((user) => setCreator(user));
    }
  }, []);

  useEffect(() => {
    if (activeConv) {
      updateMsg("conversationId", activeConv._id);
    }
  }, [activeConv]);

  const updateMsg = (name, value) => {
    setMsg((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    msg.timestamp = Date.now();

    if (sharedPubli) {
      msg.sharedEventId = sharedPubli.event_id;
    }

    // Enregistrer le message d'origine dans la base de données
    const savedMessage = await sendMessage(msg, user);
    socket.emit("newMessage", {
      conversationId: savedMessage.conversationId,
      message: savedMessage,
    });

    updateMsg("content", "");
  };

  if (sharedPubli) {
    if (!event || !creator) {
      return <Loader />;
    }
  }

  return (
    <>
      {sharedPubli ? (
        <div className="shared_publi_container">
          <FontAwesomeIcon
            icon={faXmark}
            className="icon"
            onClick={() => setSharedPubli(null)}
          />
          <DetailedEventCard
            event={event}
            publication={sharedPubli}
            creator={creator}
            loggedInUser={user}
            detailed={false}
          />
        </div>
      ) : null}
      <animated.div style={animation} className="chatbox_input">
        <div className="input_wrapper">
          <div className="icon_wrapper">
            <FontAwesomeIcon icon={faCamera} className="icon" />
            <FontAwesomeIcon icon={faPaperclip} className="icon" />
          </div>
          <form className="message_input_box" onSubmit={handleSubmitForm}>
            <input
              type="text"
              name="content"
              className="message_input"
              placeholder="Votre message..."
              value={msg.content}
              onChange={(e) => updateMsg("content", e.target.value)}
            />
            <button>
              <img src="/assets/images/send_message.png" alt="Send" />
            </button>
          </form>
          <div className="icon_wrapper">
            <FontAwesomeIcon icon={faMicrophone} className="icon" />
          </div>
        </div>
      </animated.div>
    </>
  );
}
