import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { ParticipatingFriendList } from "../../../Entities/Event/ParticipatingFriendList";
import dayjs from "dayjs";
import { PublicationFooter } from "./PublicationFooter";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
import {
  getLikes,
  likeContent,
  unlikeContent,
} from "../../../../utils/fetchs/likeFetchs";
const api_url = getImgUrl();

export default function DetailedEventCard({
  event,
  publication = null,
  creator,
  loggedInUser,
  detailed = true,
}) {
  const model = "Publication";
  const [isLiked, setIsLiked] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const handleLike = async () => {
    await likeContent(publication._id, model, loggedInUser);
    setIsLiked(true);
  };

  const handleDislike = async () => {
    await unlikeContent(publication._id, model, loggedInUser);
    setIsLiked(false);
  };
  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const likes = await getLikes(publication._id, model);
        setLikesCount(likes.length);
        setIsLiked(likes.some((like) => like.user === loggedInUser._id));
      } catch (error) {
        console.error("Failed to fetch likes", error);
      }
    };

    fetchLikes();
  }, [publication._id, loggedInUser._id]);

  const handleLikeClick = async () => {
    try {
      if (isLiked) {
        await unlikeContent(event._id, model, loggedInUser);
        setLikesCount((prevCount) => prevCount - 1);
      } else {
        await likeContent(event._id, model, loggedInUser);
        setLikesCount((prevCount) => prevCount + 1);
      }
      setIsLiked(!isLiked);
    } catch (error) {
      console.error("Failed to toggle like", error);
    }
  };
  return (
    <>
      <div className="post_event">
        {event.picture && (
          <Link to={`/event/${event._id}`} className="post_image_container">
            <img
              src={`${api_url}/uploads/events/${event.picture}`}
              className="post_image"
              alt="publication"
            />
          </Link>
        )}

        <div className="post_content">
          <Link to={`/event/${event._id}`}>
            <p className="title">{event.title}</p>
          </Link>
          <Link to="/map">
            <p className="post_location">
              <FontAwesomeIcon icon={faLocationDot} />{" "}
              {event.location_formatted} {/* TODO recup icon🇫🇷*/}
            </p>
          </Link>
          {detailed ? (
            <div className="icons_wrap space_right">
              <ParticipatingFriendList participants={event.participants} />
              <img
                src={
                  "/assets/images/heart" +
                  (isLiked ? "Filled" : "") +
                  "64px.png"
                }
                className="post_like"
                alt="like"
                onClick={handleLikeClick}
              />
            </div>
          ) : null}

          <div className="icons_wrap">
            <p className="date">
              <FontAwesomeIcon icon={faCalendar} /> Le{" "}
              {dayjs(event.date).format("DD/MM/YYYY")} à{" "}
              {dayjs(event.date).format("HH:mm")}
            </p>
            {publication ? (
              <p className="like_count">{likesCount} j'aimes</p>
            ) : null}
          </div>
          {detailed ? (
            <PublicationFooter
              publication={publication}
              event={event}
              loggedInUser={loggedInUser}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
