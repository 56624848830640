import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);

export function displayEventDate(eventDate) {
  const now = dayjs();
  const event = dayjs(eventDate);
  const oneWeekLater = now.add(1, "week");

  if (event.isSameOrAfter(now) && event.isBefore(oneWeekLater)) {
    // L'événement est dans moins d'une semaine
    return event.fromNow();
  } else {
    // L'événement est dans plus d'une semaine
    return event.format("DD/MM/YYYY à HH:mm");
  }
}

export function formatEventTitle(event) {
  return event.title
    .replaceAll("/", "&")
    .replaceAll("-", "&")
    .split(" ")
    .join("-");
}
