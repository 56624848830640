import React, { useState, useRef, useEffect } from "react";
import updatePageHead from "../../../../utils/helpers/updatePageHead";
import { translateXMinus100 } from "../../../../utils/animations/animations";
import { animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCalendarPlus,
  faMinus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../../utils/contexts/userContext";
import { useNavigate } from "react-router-dom";
import { retreiveLocation } from "../../../../utils/helpers/retreiveLocation";
import { uploadEventPicture } from "../../../../utils/fetchs/imageFetch";
import GradientButton from "../../../Common/Buttons/GradientButton";
import MainEventInfos from "./MainEventInfos";
import DetailedEventInfos from "./DetailedEventInfos";
import SearchInput from "../../../Common/Navbar/tmpl/SearchInput";
import { UserCard } from "../../User/UserCard";
import Switch from "../../../Common/Buttons/Switch";
import { isMobile } from "react-device-detect";
import { useEvent } from "../../../../utils/contexts/eventContext";

export default function CreaEventCard() {
  const cssPath = "/assets/css/components/crea_event_card.css";

  const {
    event,
    createEvent: createEventContext,
    updateEvent: updateEventContext,
    resetEvent,
  } = useEvent();
  const [isModified, setIsModified] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [eventConversation, setEventConversation] = useState(
    event.event_conversation || true
  );
  const [programEventPubli, setProgramEventPubli] = useState(
    !!event.program_publi_date
  );
  const [recurrent, setrecurrent] = useState(!!event.recurrent);
  const [customDates, setCustomDates] = useState([]);
  const [adultsOnly, setAdultsOnly] = useState(event.adults_only || false);
  const [showEndDate, setShowEndDate] = useState(!!event.end_date);
  const [privateEvent, setPrivateEvent] = useState(event.private || true);
  const [isPhone, setisPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );

  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setisPhone(isMobile || window.innerWidth < 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigate = useNavigate();
  const { user } = useUser();
  const form = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedEventData = { ...event };
    updatedEventData.organiser_id = user._id;
    updatedEventData.title = form.current["title"].value;
    updatedEventData.date = form.current["date"].value;
    updatedEventData.end_date = showEndDate
      ? form.current["end_date"].value
      : "";
    updatedEventData.description = showDetails
      ? form.current["description"].value
      : "";

    if (event.picture instanceof File) {
      updatedEventData.picture = await uploadEventPicture(event.picture);
    }

    const { loc, lat, lng } = await retreiveLocation(
      form.current["event_location"].value
    );
    updatedEventData.location_formatted = loc;
    updatedEventData.location_coordinates = [lat, lng];

    if (programEventPubli) {
      updatedEventData.program_publi_date =
        form.current["program_publi_date"].value;
    }

    updatedEventData.adults_only = adultsOnly;
    updatedEventData.private = privateEvent;

    if (recurrent && customDates.length > 0) {
      updatedEventData.custom_dates = customDates;
    }

    // Enregistrer ou mettre à jour l'événement
    if (event) {
      await updateEventContext(updatedEventData);
    } else {
      await createEventContext(updatedEventData);
    }

    navigate(`/events`);
  };

  // Fonction pour comparer les valeurs initiales de l'événement avec les valeurs actuelles
  const checkForChanges = () => {
    const initialData = event || {};
    const currentData = {
      title: form.current["title"].value,
      date: form.current["date"].value,
      end_date: form.current["end_date"]?.value || "",
      description: form.current["description"]?.value || "",
      // Ajoute ici d'autres champs si nécessaire
    };

    // Vérifie si des données actuelles sont différentes des données initiales
    return Object.keys(currentData).some(
      (key) => currentData[key] !== initialData[key]
    );
  };

  // Déclenchement si des changements non sauvegardés sont détectés
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isModified && location.pathname.startsWith("/eventstudio")) {
        e.preventDefault();
        e.returnValue = ""; // Nécessaire pour les navigateurs
      }
    };

    // Écoute les changements dans les champs de formulaire
    const handleInputChange = () => {
      setIsModified(checkForChanges());
    };

    // Ajouter l'écouteur pour chaque changement de champ
    if (form.current) {
      form.current.addEventListener("input", handleInputChange);
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Nettoie l'écouteur quand le composant est démonté
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (form.current) {
        form.current.removeEventListener("input", handleInputChange);
      }
    };
  }, [isModified, location.pathname]);

  return (
    <>
      {updatePageHead({ cssPath })}
      <animated.div style={translateXMinus100()} className="event_card">
        <h2 className="text_gradient">
          {event.title ? "Modifier" : "Créer"} votre évènement
        </h2>
        <form
          onSubmit={(e) => handleSubmit(e)}
          ref={form}
          className="creation_card"
        >
          <MainEventInfos
            eventData={event}
            setShowEndDate={setShowEndDate}
            showEndDate={showEndDate}
          />
          {showDetails && (
            <DetailedEventInfos
              eventData={event}
              setEventData={updateEventContext}
              privateEvent={privateEvent}
              setPrivateEvent={setPrivateEvent}
              eventConversation={eventConversation}
              setEventConversation={setEventConversation}
            />
          )}
          {showDetails && privateEvent && (
            <>
              <div className="event_invite">
                <p>Inviter des amis :</p>
                <SearchInput
                  data={event}
                  setData={updateEventContext}
                  field="guest_list"
                  filter="user"
                  showGlass={false}
                  showFilter={false}
                />
              </div>
              {event.guest_list.filter((guest) => guest._id).length > 0 && (
                <p>Invités :</p>
              )}
              {event.guest_list.filter((guest) => guest._id).length > 0 &&
                event.guest_list
                  .filter((guest) => guest._id)
                  .map((guest) => (
                    <div className="guest_infos" key={guest._id}>
                      <div className="guest">
                        <UserCard user={guest} link={false} />
                      </div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="icon"
                        onClick={() => handleRemoveGuest(guest._id)}
                      />
                    </div>
                  ))}
            </>
          )}
          {showDetails && (
            <>
              <div className="event_card_wrapper">
                <Switch
                  label="Programmer la publication :"
                  value={programEventPubli}
                  setValue={setProgramEventPubli}
                  tooltip="Si vous ne spécifiez aucune date, l'événement sera publié au moment de la création."
                />
                {programEventPubli && isPhone && (
                  <div className="event_card_input_wrapper">
                    <p className="date_txt">
                      Date et heure de la publication de l'événement :
                    </p>
                    <input
                      type="datetime-local"
                      name="program_publi_date"
                      className="date"
                      defaultValue={event.program_publi_date}
                    />
                  </div>
                )}
                <Switch
                  label="Réservé aux majeurs :"
                  value={adultsOnly}
                  setValue={setAdultsOnly}
                  tooltip="Les événements réservés aux majeurs nécessitent aux utilisateurs de vérifier leur profil Wive pour pouvoir participer."
                />
              </div>
              {programEventPubli && !isPhone && (
                <div className="event_card_input_wrapper">
                  <p className="date_txt">
                    Date et heure de la publication de l'événement :
                  </p>
                  <input
                    type="datetime-local"
                    name="program_publi_date"
                    className="date"
                    defaultValue={event.program_publi_date}
                  />
                </div>
              )}
              <Switch
                label="Événement récurrent :"
                value={recurrent}
                setValue={setrecurrent}
                tooltip="Activez cette option si cet événement est amené à se répéter régulièrement, comme une réunion hebdomadaire ou un événement mensuel."
              />
              {recurrent && (
                <div className="event_card_input_wrapper">
                  <p className="date_txt">Selectionnez la fréquence :</p>
                  <input
                    type="datetime-local"
                    name="program_publi_date"
                    className="date"
                    defaultValue={event.program_publi_date}
                  />
                </div>
              )}
            </>
          )}
          <div className="event_card_wrapper">
            <button
              type="button"
              className="details_btn"
              onClick={() => setShowDetails(!showDetails)}
            >
              <FontAwesomeIcon
                icon={showDetails ? faMinus : faPlus}
                className="icon"
              />
              {showDetails ? "Moins de détails" : "Plus de détails"}
            </button>
            <GradientButton
              icon={faCalendarPlus}
              text={event.title ? "Modifier votre event" : "Créer votre évent"}
              padding={"0px 30px"}
            />
          </div>
        </form>
      </animated.div>
    </>
  );
}
