export function getApiUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://wive.fr/api"
    : "http://localhost:8080/api";
}

export function getImgUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://wive.fr"
    : "http://localhost:8080";
}

export function getDiscordUrl() {
  return process.env.NODE_ENV === "production"
    ? "https://discord.com/oauth2/authorize?client_id=1289565457174757439&response_type=code&redirect_uri=https%3A%2F%2Fwive.fr%2Fdiscord&scope=identify+email"
    : "https://discord.com/oauth2/authorize?client_id=1289565457174757439&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord&scope=identify+email";
}
