import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

export function BackgroundGradientLur() {
  const [flip, set] = useState(false);

  const { opacity } = useSpring({
    opacity: flip ? 0.25 : 0.15,
    config: { duration: Math.floor(Math.random() * 2000 + 1000) },
    onRest: () => set(!flip),
  });

  return <animated.div style={{ opacity }} className="gradient" />;
}
