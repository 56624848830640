import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Portal } from "react-portal";
import updateHead from "../../../utils/helpers/updatePageHead";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import GradientButton from "../../Common/Buttons/GradientButton";
import { Link } from "react-router-dom";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "../../../utils/contexts/userContext";
import { UserCard } from "../../Entities/User/UserCard";
const api_url = getImgUrl();

export function UserList({
  userList,
  buttonAction, // action to perform when button is clicked (optional)
  shouldBeOpen,
  setShouldBeOpen,
  title, // the title passed to display the correct context
  buttonIcon = faTrash, // default icon for the button
  buttonLabel = "Remove", // default label for the button
}) {
  const { user } = useUser();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cssPath = "/assets/css/components/user_list.css"; // Set the CSS to import

  const isLoggedIn = user && user.email;

  useEffect(() => {
    setUsers(userList);
    setFilteredUsers(userList);
  }, [userList]);

  useEffect(() => {
    if (shouldBeOpen) {
      openModal();
    } else {
      closeModal();
    }
  }, [shouldBeOpen]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setShouldBeOpen(false);
  };

  const handleSearch = (value) => {
    setFilteredUsers(
      users.filter((user) =>
        user.username.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };

  const modalTransition = useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });

  const opacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  return (
    <>
      {updateHead({ cssPath })}
      {modalIsOpen && (
        <Portal>
          <animated.div
            className="modal_backdrop"
            style={opacityTransition}
            onClick={closeModal}
          ></animated.div>
          <animated.div className="modal_box" style={modalTransition}>
            <button className="close_modal_button" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {isLoggedIn ? (
              <>
                <div className="modal_follow_title_container">
                  <h2>{title}</h2>
                </div>
                <input
                  className="follow_search"
                  type="text"
                  placeholder="Rechercher"
                  onChange={(e) => handleSearch(e.target.value)}
                ></input>
                <ul className="modal_follow_list">
                  {filteredUsers.map((user) => (
                    <li key={user._id}>
                      <Link to={`/${user.username}`} onClick={closeModal}>
                        <div className="user_card_container">
                          <img
                            className="pp_follow"
                            src={`${api_url}/uploads/profilepics/${user.profilepic}`}
                            alt="user profile"
                          />
                          <p>
                            @{user.username} <br />{" "}
                            <small>&emsp;{user.description}</small>
                          </p>
                        </div>
                      </Link>

                      {buttonAction && (
                        <GradientButton
                          icon={buttonIcon}
                          round={true}
                          onClick={() => buttonAction(user._id)}
                          label={buttonLabel}
                        />
                      )}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <div className="login">
                <p>
                  Connectez vous pour avoir accès aux fonctionnalités de Wive.
                </p>
                <GradientButton type={"login"} width={"60%"} />
              </div>
            )}
          </animated.div>
        </Portal>
      )}
    </>
  );
}
