import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserList } from "../../../Modals/UserList/UserList";
import { ParticipatingFriendList } from "../../../Entities/Event/ParticipatingFriendList";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function ParticpantsCard({ participants, participantCount }) {
  const [shouldBeOpen, setShouldBeOpen] = useState(false);
  return (
    <>
      <div className="flex_column">
        <UserList
          title="Participants"
          userList={participants}
          shouldBeOpen={shouldBeOpen}
          setShouldBeOpen={setShouldBeOpen}
        />
        <p className="event_participants" onClick={() => setShouldBeOpen(true)}>
          <FontAwesomeIcon icon={faUsers} className="icon" />
          {participantCount} participants
        </p>
        <ParticipatingFriendList participants={participants} />
      </div>
    </>
  );
}
