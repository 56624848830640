import { getApiUrl } from "../contexts/apiContext";

const api_url = getApiUrl();

export async function likeContent(targetId, onModel, user) {
  try {
    const token = await user.token;
    const userId = await user._id;
    token, userId;
    const response = await fetch(`${api_url}/like/like`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId, targetId, onModel }),
    });
    response;

    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      return; //(window.location = "/login-register?tk=true");
    } else {
      throw new Error("Failed to like content");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function unlikeContent(targetId, onModel, user) {
  try {
    const token = await user.token;
    const userId = await user._id;
    const response = await fetch(`${api_url}/like/unlike`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId, targetId, onModel }),
    });
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      return (window.location = "/login-register?tk=true");
    } else {
      throw new Error("Failed to unlike content");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getLikes(targetId, onModel) {
  try {
    const response = await fetch(
      `${api_url}/like/likes/${targetId}/${onModel}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error("Failed to get likes");
    }
  } catch (error) {
    console.error(error.message);
  }
}
