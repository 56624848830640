import React, { useEffect, useState } from "react";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { createInvite } from "../../../utils/fetchs/discordFetchs";

export default function DiscordServerCard({ discordServer, hasJoined }) {
  const [inviteLink, setInviteLink] = useState(null);

  useEffect(() => {
    if (!hasJoined) {
      async function fetchInviteLink() {
        const inviteCode = await createInvite(discordServer.rules_channel_id);
        if (inviteCode) {
          setInviteLink(`https://discord.gg/${inviteCode}`);
        }
      }
      fetchInviteLink();
    }
  }, [hasJoined, discordServer.id]);

  return (
    <>
      <div className="server_info flex_center">
        <img
          src={`https://cdn.discordapp.com/icons/${discordServer.id}/${discordServer.icon}.png`}
          alt={discordServer.name}
          className="server_icon"
        />
        <div>
          <h3>{discordServer.name}</h3>
          <p>Membres : {discordServer.approximate_member_count}</p>
        </div>
        <GradientButton
          text={hasJoined ? "Voir le serveur" : "Ouvrir l'invitation"}
          icon={faDiscord}
          // soit link si hasJoined, sinon
          link={
            inviteLink
              ? `https://discord.com/channels/${discordServer.id}`
              : inviteLink
          }
        />

        {hasJoined && <GradientButton icon={faTrash} round={true} />}
      </div>
    </>
  );
}
