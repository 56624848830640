import React from "react";
import { faCalendar, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animated } from "react-spring";
import { translateYMinus100 } from "../../../../utils/animations/animations";

export default function SearchFilters({ currentFilter, handleFilterClick }) {
  return (
    <>
      <animated.div style={translateYMinus100()} className="filter_icons">
        <FontAwesomeIcon
          icon={faUser}
          className={`icon filter ${currentFilter === "user" ? "active" : ""}`}
          onClick={() => handleFilterClick("user")}
        />
        <FontAwesomeIcon
          icon={faCalendar}
          className={`icon filter ${currentFilter === "event" ? "active" : ""}`}
          onClick={() => handleFilterClick("event")}
        />
      </animated.div>
    </>
  );
}
