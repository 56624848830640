import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Post from "./pages/EventPage/EventPage";
import Checkout from "./pages/Payement/Checkout";
import Account from "./pages/Account/Account";
import Chatbox from "./pages/Chatbox/Chatbox";
import LoginRegister from "./pages/LoginRegister/LoginRegister";
import EventStudio from "./pages/EventStudio/EventStudio";
import Map from "./pages/Map/Map";
import PersonalEvents from "./pages/Personnal Events/PersonalEvents";
import Navbar from "./components/Common/Navbar/Navbar";
import Profile from "./pages/Profile/Profile";
import Home from "./pages/Home/Home";
import Presentation from "./pages/Presentation/Presentation";
import Search from "./pages/Search/Search";
import CreaEventCard from "./components/Entities/Event/CreaEventCard/CreaEventCard";
import TwoFactorAuth from "./pages/TwoFactorAuth/TwoFactorAuth";
import Partnership from "./pages/Partnership/Partnership";
import VerifyEmail from "./pages/TwoFactorAuth/TwoFactorAuth";
import Discord from "./pages/Discord/Discord";
import Google from "./pages/Google/Google";

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />

        <Route exact path="/presentation" element={<Presentation />} />
        <Route exact path="/map" element={<Map />} />
        <Route exact path="/login-register" element={<LoginRegister />} />
        <Route exact path="/chatbox" element={<Chatbox />} />
        <Route exact path="/events" element={<PersonalEvents />} />
        <Route exact path="/account" element={<Account />} />
        <Route exact path="/:username" element={<Profile />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/create" element={<CreaEventCard />} />
        <Route exact path="/partnership" element={<Partnership />} />
        <Route exact path="/verify-email" element={<VerifyEmail />} />
        <Route exact path="/discord" element={<Discord />} />
        <Route exact path="/google" element={<Google />} />

        <Route exact path="/two-factor-auth" element={<TwoFactorAuth />} />
        <Route exact path="/event/:eventId" element={<Post />} />
        <Route exact path="/event/:title/payements" element={<Checkout />} />
        <Route exact path="/eventstudio/:eventId" element={<EventStudio />} />
      </Routes>
    </>
  );
};

export default App;
