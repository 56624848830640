import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

export async function getConversationsFromParticipants(user, participants) {
  try {
    const response = await fetch(`${api_url}/conversations/` + participants, {
      method: "GET",
    });

    switch (response.status) {
      case 200:
        const data = await response.json();
        return data.length === 0 ? null : data;
      default:
        throw new Error(response.text);
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des conversations :", error);
  }
}

export async function createConversation(user, conversation) {
  try {
    if (user._id) {
      const tk = await user.token;

      const response = await fetch(`${api_url}/conversation/create`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + tk,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user._id,
          conversation: conversation,
        }),
      });
      response.status;
      switch (response.status) {
        case 201:
          return {
            success: true,
            conversation: await response.json(),
          };
        case 401:
          window.location = "/login-register?tk=true";
          break;
        case 409:
          return {
            success: false,
            existingConversation: await response.json(),
          };
        default:
          throw new Error("Unable to create conversation");
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function updateConversation(user, conversation) {
  try {
    if (user._id) {
      const tk = await user.token;
      const response = await fetch(`${api_url}/conversation/update`, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + tk,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user._id,
          conversation: conversation,
        }),
      });

      switch (response.status) {
        case 200:
          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;
        default:
          throw new Error("Unable to update conversation");
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function addUserToConv(user, conversation) {
  try {
    if (user._id) {
      user, conversation;
      const tk = await user.token;
      const response = await fetch(`${api_url}/conversation/add`, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + tk,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user._id,
          conversationId: conversation._id,
        }),
      });

      switch (response.status) {
        case 200:
          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;
        default:
          throw new Error("Unable to update conversation");
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function deleteConversation(user, conversation) {
  try {
    if (user._id) {
      const tk = await user.token;
      const response = await fetch(`${api_url}/conversation/delete`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + tk,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user._id,
          conversation: conversation,
        }),
      });

      switch (response.status) {
        case 200:
          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;
        default:
          throw new Error("Unable to delete conversation");
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getUserConversations(user) {
  try {
    if (user._id) {
      const tk = await user.token;
      const response = await fetch(`${api_url}/conversation/${user._id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tk,
        },
      });

      switch (response.status) {
        case 200:
          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;
        default:
          throw new Error(
            `Unable to retreive conversation with id : ${conversationId}`
          );
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getConversationByEventId(eventId, user) {
  try {
    if (user._id) {
      const tk = await user.token;
      const response = await fetch(`${api_url}/conversation/event/${eventId}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + tk,
        },
      });
      switch (response.status) {
        case 200:
          "ici";

          return await response.json();
        case 401:
          window.location = "/login-register?tk=true";
          break;
        default:
          throw new Error(
            `Unable to retreive conversation with id : ${conversationId}`
          );
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}
