import React, { useState } from "react";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import Switch from "../../../components/Common/Buttons/Switch";

export default function ModifyNotif() {
  // États pour chaque option détaillée
  const [pushNotifications, setPushNotifications] = useState(true);
  const [pauseAllNotifications, setPauseAllNotifications] = useState(false);

  // Notifications d'événements
  const [eventInvitesAll, setEventInvitesAll] = useState(false);
  const [eventInvitesFollowing, setEventInvitesFollowing] = useState(true);
  const [eventInvitesNone, setEventInvitesNone] = useState(false);
  const [eventRemindersAll, setEventRemindersAll] = useState(false);
  const [eventRemindersFollowing, setEventRemindersFollowing] = useState(true);
  const [eventRemindersNone, setEventRemindersNone] = useState(false);

  // Exemple supplémentaire pour publications
  const [postNotificationsAll, setPostNotificationsAll] = useState(false);
  const [postNotificationsFollowing, setPostNotificationsFollowing] =
    useState(true);
  const [postNotificationsNone, setPostNotificationsNone] = useState(false);

  return (
    <section className="notification">
      {/* Notifications push */}
      <article className="general">
        <Switch
          label="Notifications push"
          value={pushNotifications}
          setValue={setPushNotifications}
          tooltip="Activez ou désactivez toutes les notifications push."
        />
        <Switch
          label="Tout mettre en pause"
          value={pauseAllNotifications}
          setValue={setPauseAllNotifications}
          tooltip="Mettez toutes les notifications en pause temporairement."
        />
      </article>

      {/* Notifications d'événements */}
      <article className="events">
        <h2>Notifications d'événements</h2>
        <div className="switch-group">
          <p>Invitations aux événements</p>
          <Switch
            label="De tout le monde"
            value={eventInvitesAll}
            setValue={(checked) => {
              setEventInvitesAll(checked);
              if (checked) {
                setEventInvitesFollowing(false);
                setEventInvitesNone(false);
              }
            }}
            tooltip="Recevez des invitations d'événements de tous les utilisateurs."
          />
          <Switch
            label="Des personnes que je suis"
            value={eventInvitesFollowing}
            setValue={(checked) => {
              setEventInvitesFollowing(checked);
              if (checked) {
                setEventInvitesAll(false);
                setEventInvitesNone(false);
              }
            }}
            tooltip="Recevez des invitations uniquement des utilisateurs que vous suivez."
          />
          <Switch
            label="Aucune notification"
            value={eventInvitesNone}
            setValue={(checked) => {
              setEventInvitesNone(checked);
              if (checked) {
                setEventInvitesAll(false);
                setEventInvitesFollowing(false);
              }
            }}
            tooltip="Ne recevez aucune notification d'invitation aux événements."
          />
        </div>

        <div className="switch-group">
          <p>Rappels d'événements</p>
          <Switch
            label="De tout le monde"
            value={eventRemindersAll}
            setValue={(checked) => {
              setEventRemindersAll(checked);
              if (checked) {
                setEventRemindersFollowing(false);
                setEventRemindersNone(false);
              }
            }}
            tooltip="Recevez des rappels d'événements de tous les utilisateurs."
          />
          <Switch
            label="Des personnes que je suis"
            value={eventRemindersFollowing}
            setValue={(checked) => {
              setEventRemindersFollowing(checked);
              if (checked) {
                setEventRemindersAll(false);
                setEventRemindersNone(false);
              }
            }}
            tooltip="Recevez des rappels uniquement pour les événements des utilisateurs que vous suivez."
          />
          <Switch
            label="Aucune notification"
            value={eventRemindersNone}
            setValue={(checked) => {
              setEventRemindersNone(checked);
              if (checked) {
                setEventRemindersAll(false);
                setEventRemindersFollowing(false);
              }
            }}
            tooltip="Ne recevez aucune notification de rappel d'événement."
          />
        </div>
      </article>

      {/* Notifications de publications */}
      <article className="posts">
        <h2>Notifications de publications</h2>
        <div className="switch-group">
          <p>Publications</p>
          <Switch
            label="De tout le monde"
            value={postNotificationsAll}
            setValue={(checked) => {
              setPostNotificationsAll(checked);
              if (checked) {
                setPostNotificationsFollowing(false);
                setPostNotificationsNone(false);
              }
            }}
            tooltip="Recevez des notifications pour les publications de tous les utilisateurs."
          />
          <Switch
            label="Des personnes que je suis"
            value={postNotificationsFollowing}
            setValue={(checked) => {
              setPostNotificationsFollowing(checked);
              if (checked) {
                setPostNotificationsAll(false);
                setPostNotificationsNone(false);
              }
            }}
            tooltip="Soyez informé des nouvelles publications des utilisateurs que vous suivez."
          />
          <Switch
            label="Aucune notification"
            value={postNotificationsNone}
            setValue={(checked) => {
              setPostNotificationsNone(checked);
              if (checked) {
                setPostNotificationsAll(false);
                setPostNotificationsFollowing(false);
              }
            }}
            tooltip="Ne recevez aucune notification de publications."
          />
        </div>
      </article>

      {/* Autres catégories de notifications peuvent être structurées de la même manière */}

      {/* Bouton Enregistrer */}
      <GradientButton type={"save"} label="Enregistrer les modifications" />
    </section>
  );
}
