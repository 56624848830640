import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

async function fetchList(user, endpoint, method = "GET", body = null) {
  try {
    const tk = await user.token;
    const options = {
      method,
      headers: {
        Authorization: "Bearer " + tk,
        "Content-Type": "application/json",
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${api_url}/list/${endpoint}`, options);

    switch (response.status) {
      case 200:
      case 201:
        return await response.json();
      case 400:
        throw new Error("Erreur de validation");
      case 401:
        return (window.location.href = "/login-register?tk=true");
      case 404:
        throw new Error("Ressource non trouvée");
      default:
        throw new Error("Erreur inconnue");
    }
  } catch (error) {
    console.error("Erreur :", error);
  }
}

// Récupérer toutes les listes
export async function getAllLists(user) {
  return fetchList(user, "lists");
}

// Récupérer une liste par ID
export async function getListById(user, listId) {
  return fetchList(user, `${listId}`);
}

// Récupérer une liste par ID d'user
export async function getListByUserId(user) {
  return fetchList(user, `user/${user._id}`);
}

// Créer une nouvelle liste
export async function createList(user, list) {
  return fetchList(user, "/create", "POST", list);
}

// Mettre à jour une liste par ID
export async function updateList(user, listId, list) {
  return fetchList(user, `${listId}`, "PUT", list);
}

// Supprimer une liste par ID
export async function deleteList(user, listId) {
  return fetchList(user, `${listId}`, "DELETE");
}
