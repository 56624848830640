import React, { useEffect, useRef, useState } from "react";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import GradientButton from "../../../Common/Buttons/GradientButton";
import Switch from "../../../Common/Buttons/Switch";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function DetailedEventInfos({
  eventData,
  setEventData,
  privateEvent,
  setPrivateEvent,
  eventConversation,
  setEventConversation,
}) {
  const fileInputRef = useRef();
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (eventData.picture) {
      // Si eventData.picture est une URL (par exemple, lorsque vous modifiez un événement déjà existant)
      if (typeof eventData.picture === "string") {
        setImagePreview(`${api_url}/uploads/events/${eventData.picture}`);
      }

      // Si eventData.picture est un fichier (par exemple, un nouvel upload)
      else if (eventData.picture instanceof File) {
        setImagePreview(URL.createObjectURL(eventData.picture));
      }
    }
  }, [eventData.picture]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    const newEventData = { ...eventData, picture: file };
    setEventData(newEventData);
  };

  const triggerFileInput = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <>
      <section id="details">
        <div className="event_card_wrapper"></div>
        <div className="form_group">
          <textarea
            name="description"
            defaultValue={eventData.description}
            placeholder="Description de l'événement"
          />
          <div className="image_container">
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="event Thumbnail"
                className="picture"
                onClick={triggerFileInput}
              />
            ) : (
              <GradientButton
                text={"Ajouter une image"}
                icon={faImage}
                width={"40%"}
                onClick={triggerFileInput}
              />
            )}
            <input
              type="file"
              name="eventpic"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="event_card_wrapper">
          <Switch
            label="Événement sur invitation :"
            value={privateEvent}
            setValue={setPrivateEvent}
          />
          <Switch
            label="Conversation d'événement :"
            value={eventConversation}
            setValue={setEventConversation}
          />
        </div>
      </section>
    </>
  );
}
