import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

export async function createPayementIntent(user) {
  try {
    const response = await fetch(`${api_url}/payement/create-payement-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: user._id,
        items: [{ id: "xl-tshirt" }],
      }),
    });

    const clientSecret = await response.json();
    return clientSecret;
  } catch (error) {
    console.error(error.message);
  }
}
