import { UserCard } from "../../../Entities/User/UserCard";
import PublicationSettings from "./PublicationSettings";

export function PublicationHeader({ creator }) {
  return (
    <>
      <div className="info">
        <UserCard user={creator} />
        <PublicationSettings />
      </div>
    </>
  );
}
