import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { GoogleLogin } from "@react-oauth/google";
import { loginWithGoogle } from "../../../utils/fetchs/googleFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import { useNavigate } from "react-router-dom";
import { getDiscordUrl } from "../../../utils/contexts/apiContext";

export default function ToggleChoice({ showLogin, setShowLogin }) {
  const { login } = useUser();
  const discordUrl = getDiscordUrl();
  const navigate = useNavigate();
  const handleGoogleLoginSuccess = async (response) => {
    try {
      const googleToken = response.credential;
      const { user, token } = await loginWithGoogle(googleToken);
      login(user, token);
      return navigate("/");
    } catch (error) {
      console.error("Erreur de connexion Google :", error);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Erreur de connexion Google :", error);
  };

  return (
    <>
      <div className="or">
        <hr />
        <span>OU</span>
        <hr />
      </div>
      <div className="flex_center">
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
          cookiePolicy={"single_host_origin"}
        />
      </div>
      <a className="discord_btn" href={discordUrl}>
        <FontAwesomeIcon icon={faDiscord} />
      </a>
      <div className="toggle_link">
        <div className="goto">
          {showLogin ? (
            <p>
              Pas encore de compte ?{" "}
              <div className="toggle_link">
                <a href="#" onClick={() => setShowLogin(!showLogin)}>
                  Inscription
                </a>
              </div>
            </p>
          ) : (
            <p>
              Vous avez déjà un compte ?{" "}
              <div className="toggle_link">
                <a href="#" onClick={() => setShowLogin(!showLogin)}>
                  Connexion
                </a>
              </div>
            </p>
          )}
        </div>
      </div>
    </>
  );
}
