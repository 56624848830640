import React, { createContext, useState } from "react";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <MenuContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        toggleMenu,
        pageTitle,
        setPageTitle,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
