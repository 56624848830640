import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import PublicationSettings from "../tmpl/PublicationSettings";
import { Link } from "react-router-dom";
import { getUserByUserId } from "../../../../utils/fetchs/userFetchs";
import { PublicationFooter } from "../tmpl/PublicationFooter";
import { UserCard } from "../../../Entities/User/UserCard";
import { isMobile } from "react-device-detect";
import CalendarCard from "../tmpl/CalendarCard";
import MapInner from "../../../Entities/Map/MapInner";
import { MapContainer } from "react-leaflet";
import GradientButton from "../../Buttons/GradientButton";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
import {
  likeContent,
  unlikeContent,
} from "../../../../utils/fetchs/likeFetchs";
import getEventInfos from "../tmpl/getEventInfos";
import ParticpantsCard from "../tmpl/ParticipantsCard";
const api_url = getImgUrl();

export function PublicationEventRelease({ publication, loggedInUser }) {
  const [event, setEvent] = useState(null);
  const [isPhone, setIsPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const [participants, setParticipants] = useState([]);

  const model = "Event";
  const [creator, setCreator] = useState(null);
  const [isLiked, setIsLiked] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const [participantCount, setParticipantCount] = useState(0);

  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    getUserByUserId(publication.creator_id).then((user) => setCreator(user));
  }, [publication]);

  useEffect(() => {
    const eventId = publication.event_id;
    getEventInfos({
      eventId,
      loggedInUser,
      setLikesCount,
      setIsLiked,
      setParticipantCount,
      setParticipants,
      setEvent,
    });
  }, [publication, loggedInUser]);

  if (!event || !creator) {
    return <Loader />;
  }

  const position = [
    event.location_coordinates[0],
    event.location_coordinates[1],
  ];

  const handleLikeClick = async () => {
    try {
      if (isLiked) {
        await unlikeContent(event._id, model, loggedInUser);
        setLikesCount((prevCount) => prevCount - 1);
      } else {
        await likeContent(event._id, model, loggedInUser);
        setLikesCount((prevCount) => prevCount + 1);
      }
      setIsLiked(!isLiked);
    } catch (error) {
      console.error("Failed to toggle like", error);
    }
  };
  return (
    <>
      <li className="post">
        <div className="publi_event_release" />

        <div className="info">
          <Link to={`/event/${event._id}`}>
            <p className="title text_gradient">{event.title}</p>
          </Link>
          <PublicationSettings />
        </div>
        <div className="info post_infos">
          <div className="event_creator">
            <p>Par :</p>
            <div className="info">
              <UserCard user={creator} />
            </div>
          </div>

          <ParticpantsCard
            event={event}
            participants={participants}
            participantCount={participantCount}
          />
        </div>
        <div className="post_image_container">
          <Link to={`/event/${event._id}`}>
            <img
              src={`${api_url}/uploads/events/${event.picture}`}
              className="post_image"
              alt="publication"
            />
          </Link>
          <CalendarCard event={event} />
          <div className="post_infos_container">
            {isPhone ? (
              <img
                src={
                  "/assets/images/heart" +
                  (isLiked ? "Filled" : "") +
                  "64px.png"
                }
                className="post_like"
                alt="like"
                onClick={handleLikeClick}
              />
            ) : (
              <GradientButton
                type={"like"}
                padding={"0px 25px"}
                filled={isLiked}
                onClick={handleLikeClick}
              />
            )}

            <p className="like_count">{likesCount} j'aimes</p>

            <MapContainer center={position} zoom={13}>
              <MapInner events={[event]} handleBounds={setBounds} />
            </MapContainer>
          </div>
        </div>
        <PublicationFooter
          publication={publication}
          event={event}
          loggedInUser={loggedInUser}
        />
      </li>
    </>
  );
}
