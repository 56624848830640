// src/components/LeftColMenu.js
import React, { useState, useEffect, useContext } from "react";
import MenuItem from "./MenuItem";
import updateHead from "../../../utils/helpers/updatePageHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { MenuContext } from "../../../utils/contexts/menuContext";

export default function LeftColMenu({ items, dividerClass, pageTitle }) {
  const cssPath = "/assets/css/components/left_col_menu.css";
  const { menuOpen, setPageTitle } = useContext(MenuContext);

  useEffect(() => {
    if (pageTitle) {
      setPageTitle(pageTitle);
    }
  }, [setPageTitle]);

  return (
    <>
      {updateHead({ cssPath })}
      {menuOpen && (
        <div className="left_col_menu">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <MenuItem
                icon={item.icon}
                label={item.label}
                percentage={item.percentage}
                onClick={item.onClick}
              />
              {index < items.length - 1 && <div className={dividerClass}></div>}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
}
