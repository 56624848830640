import React, { useState, useEffect } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import Feed from "../../components/Common/Feed/Feed";
import ProfileCard from "./tmpl/ProfileCard";
import SuggestionsList from "./tmpl/SuggestionsList";
import CreaEventCard from "../../components/Entities/Event/CreaEventCard/CreaEventCard";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { getAllPublications } from "../../utils/fetchs/publicationFetchs";
import { isMobile } from "react-device-detect";
import { useUser } from "../../utils/contexts/userContext";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const cssPath = "/assets/css/pages/home.css";
  const title = "Wive | Feed";
  const [publications, setPublications] = useState(null);
  const { user } = useUser();
  const navigate = useNavigate();

  if (user.token && !user.verified) {
    navigate("/two-factor-auth");
  }

  useEffect(() => {
    getAllPublications().then((publications) => setPublications(publications));
  }, []);

  return (
    <>
      {updateHead({ title, cssPath })}
      <section className="main">
        <BackgroundGradientLur />
        <div className="wrapper">
          <div className="left_col">
            {!isMobile && <CreaEventCard />}
            {publications && <Feed publications={publications} />}
          </div>
          <div className="right_col">
            <ProfileCard />
            <SuggestionsList />
          </div>
        </div>
      </section>
    </>
  );
}
