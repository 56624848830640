import React, { useContext } from "react";
import GradientButton from "../../Buttons/GradientButton";
import NavItems from "./NavItems";
import SearchInput from "./SearchInput";
import { Link, useNavigate } from "react-router-dom";
import { useSpring, animated, config } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faArrowLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { MenuContext } from "../../../../utils/contexts/menuContext";

export default function NavHeader({
  isLoggedIn,
  mobileDisplay,
  isSearchPage,
  isChatbox,
}) {
  const navbarHeight = scrollY > 50 ? "80px" : "100px";
  const navbarAnimation = useSpring({
    from: { transform: "translateY(-100%)" },
    to: { transform: "translateY(0%)", height: navbarHeight },
    config: config.slow,
  });
  const navigate = useNavigate();
  const { menuOpen, setMenuOpen, pageTitle } = useContext(MenuContext);
  const menuPage =
    location.pathname === "/account" ||
    location.pathname.startsWith("/eventstudio");

  const handleBackClick = () => {
    if (mobileDisplay && location.pathname.startsWith("/eventstudio")) {
      navigate(-1);
    } else {
      setMenuOpen(true); // Ouvre le menu
    }
  };

  return (
    <>
      <animated.nav style={navbarAnimation} className="navbar">
        <div className="nav_wrapper">
          {mobileDisplay && menuPage && setMenuOpen && !menuOpen ? (
            <button onClick={handleBackClick} className="back_button">
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          ) : (
            <Link to={"/"}>
              <img
                className="logo_wive"
                src="/assets/images/logo.png"
                alt="Logo Wive"
              />
            </Link>
          )}

          {(isLoggedIn && !mobileDisplay) || (mobileDisplay && isSearchPage) ? (
            <SearchInput />
          ) : null}

          {menuPage && mobileDisplay && pageTitle && (
            <div className="navbar_title">{pageTitle}</div>
          )}

          {isLoggedIn ? (
            <NavItems isChatbox={isChatbox} />
          ) : (
            <GradientButton
              icon={faUser}
              text={"Connexion"}
              link={"/login-register?feed=true"}
              padding={"10px"}
            />
          )}
        </div>
      </animated.nav>
    </>
  );
}
