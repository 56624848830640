import React, { useState } from "react";
import updateHead from "../../../utils/helpers/updatePageHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function Switch({ label, value, setValue, tooltip }) {
  const [hoveredMessage, setHoveredMessage] = useState("");
  const cssPath = "/assets/css/components/switch.css";

  return (
    <>
      {updateHead({ cssPath })}
      <div className="switch_input_wrapper">
        {tooltip && (
          <div className="tooltip-container">
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="info_icon"
              onMouseEnter={() => setHoveredMessage(tooltip)}
              onMouseLeave={() => setHoveredMessage("")}
            />
            {hoveredMessage === tooltip && (
              <p className="hovered_msg">{tooltip}</p>
            )}
          </div>
        )}

        <p>{label}</p>
        <label className="switch">
          <input
            type="checkbox"
            defaultChecked={value}
            onChange={(e) => setValue(e.currentTarget.checked)}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
}
