import { getApiUrl } from "../contexts/apiContext";

const api_url = getApiUrl();

export async function loginWithGoogle(googleToken) {
  try {
    const response = await fetch(`${api_url}/google/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ googleToken }),
    });

    switch (response.status) {
      case 200:
      case 201:
        const { user, token } = await response.json();
        return { user, token };
      case 400:
        throw new Error("Erreur de validation");
      case 401:
        throw new Error("Authentification échouée");
      case 404:
        throw new Error("Ressource non trouvée");
      default:
        throw new Error("Erreur inconnue");
    }
  } catch (error) {
    console.error("Erreur :", error);
    throw error;
  }
}
