import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

export async function createPublication(publicationData) {
  try {
    const response = await fetch(`${api_url}/publication/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(publicationData),
    });

    switch (response.status) {
      case 201:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to create publication");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getPublicationById(publicationId) {
  try {
    const response = await fetch(`${api_url}/publication//${publicationId}`, {
      method: "GET",
    });
    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error(response.text);
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getAllPublications() {
  try {
    const response = await fetch(`${api_url}/publication`, {
      method: "GET",
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error(response.text);
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getPublicationsFromUserId(userId) {
  try {
    const response = await fetch(`${api_url}/publication/${userId}`, {
      method: "GET",
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to retreive publication for user id");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getPublicationsFromEventId(eventId) {
  try {
    const response = await fetch(`${api_url}/publication/event/${eventId}`, {
      method: "GET",
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to retreive publication for user id");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function updatePublication(publicationId, publicationData) {
  try {
    const response = await fetch(
      `${api_url}/publication/update/${publicationId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(publicationData),
      }
    );

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("unable to update publication");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function deletePublication(publication) {
  try {
    const response = await fetch(`${api_url}/publication/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(publication),
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to delete publication");
    }
  } catch (error) {
    console.error(error.message);
  }
}
