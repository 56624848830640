import Calendar from "react-calendar";
import { animated } from "react-spring";
import { translateYMinus100 } from "../../../utils/animations/animations";

export default function EventsCalendar({eventsDates}) {

    const topAnimation = translateYMinus100()

    return (
        <animated.div style={topAnimation} className="calendar_section">
            <h2 className="text_gradient">Événements prévus :</h2>
            <Calendar
                value={eventsDates}
                tileClassName={({ date, view }) => {
                    if (
                        eventsDates.find(
                            (dDate) => dDate.toDateString() === date.toDateString()
                        )
                    ) {
                        return "highlight";
                    }
                }}
            />
        </animated.div>
    )
}