import { Link } from "react-router-dom";
import { animated } from "react-spring";
import { useEffect, useState } from "react";
import { translateYMinus100 } from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { getUserByUserId } from "../../../utils/fetchs/userFetchs";
const api_url = getImgUrl();

export function UserCard({ user, link = true, clearInput, subText }) {
  const [boss, setBoss] = useState(null);
  const [gilbert, setGilbert] = useState(null);

  const bossId = "653189026fc3e182fb0cc01a";
  const gilbertId = "66820b331c49476d9e90868d";

  useEffect(() => {
    // Fonction pour récupérer les informations de Martin et Gilbert
    const fetchUsers = async () => {
      try {
        const [bossData, gilbertData] = await Promise.all([
          getUserByUserId(bossId),
          getUserByUserId(gilbertId),
        ]);
        setBoss(bossData);
        setGilbert(gilbertData);
      } catch (error) {
        console.error("Erreur lors du fetch des utilisateurs :", error);
      }
    };

    fetchUsers();
  }, []);

  // Utiliser les données de boss ou gilbert si l'utilisateur est "boss" ou "gilbert"
  const currentUser =
    user && user === "boss"
      ? boss
      : user && user === "gilbert"
      ? gilbert
      : user;

  const profile_picture =
    currentUser && currentUser.profilepic
      ? currentUser.profilepic
      : "ppplaceholder.jpeg";

  const cardContent = (
    <>
      <div className="profile_picture pp_wrapper">
        <img
          src={`${api_url}/uploads/profilepics/${profile_picture}`}
          alt="profil"
          className="profile_picture"
        />
      </div>
      <p className="username">
        @{currentUser?.username || "Utilisateur supprimé"}
        <br />
        {subText}
      </p>
    </>
  );

  return (
    <>
      <animated.div style={translateYMinus100()}>
        {link ? (
          <Link
            to={`/${currentUser?.username}`}
            className="user"
            onClick={clearInput}
          >
            {cardContent}
          </Link>
        ) : (
          <div className="user">{cardContent}</div>
        )}
      </animated.div>
    </>
  );
}
