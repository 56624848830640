import { Link } from "react-router-dom";
import { getImgUrl } from "../../../utils/contexts/apiContext";

const api_url = getImgUrl();

export default function SingleUserPicture({ user }) {
  const profile_picture =
    user && user.profilepic ? user.profilepic : "ppplaceholder.jpeg";
  const username = user && user.username;
  return (
    <Link className="pp_wrapper" to={`/${username}`}>
      <img
        src={`${api_url}/uploads/profilepics/${profile_picture}`}
        alt="profile_picture"
        className="chatbox_sender_pp chatbox_cover"
      />
    </Link>
  );
}
