import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import emailjs from "@emailjs/browser";

export default function Feedback() {
  const { user } = useUser();
  const [messageData, setMessageData] = useState({
    lastname: user.lastname,
    firstname: user.firstname,
    phone: user.phonenumber,
    mail: user.email,
    message: "",
  });

  const sendMessage = () => {
    if (messageData.message) {
      // send thanks to emailjs
    }
  };

  return (
    <>
      <section className="signal">
        <p>Vous avez remarqué un bug ? Faites le nous savoir !</p>
        <textarea
          name="textarea"
          onChange={(e) =>
            setMessageData((prev) => ({ ...prev, message: e.target.value }))
          }
        ></textarea>
        <div className="ticket_btn_outline">
          <button className="ticket_btn">
            Envoyer{" "}
            <FontAwesomeIcon
              icon={faPaperPlane}
              onClick={() => sendMessage()}
            />
          </button>
        </div>
      </section>
    </>
  );
}
