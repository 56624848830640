import { useEffect, useState } from "react";
import { getAllUsernames } from "../fetchs/userFetchs";

//A refaire
export function checkUsernameValidity(username) {
  const [usernames, setUsernames] = useState([]);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);

  useEffect(() => {
    const retrieveUsernames = async () => {
      const res = await getAllUsernames();
      setUsernames(res);
    };

    retrieveUsernames();
  }, []);

  useEffect(() => {
    if (!usernames.includes(username)) {
      setIsUsernameAvailable(true);
    }
  }, [username, usernames]);

  return isUsernameAvailable;
}

export function calculateCompletion(sectionData) {
  if (!sectionData) {
    // Return 0% if data is not present
    return 0;
  }

  // Assume each filled field contributes equally to completion
  const totalFields = Object.keys(sectionData).length;
  let filledFields = 0;

  // Count only non-null, non-undefined, and non-empty string fields
  Object.values(sectionData).forEach((value) => {
    if (value || value === 0) {
      filledFields += 1;
    }
  });

  // Prevent division by zero
  if (totalFields === 0) {
    return 0;
  }

  // Calculate and round the completion percentage
  return Math.round((filledFields / totalFields) * 100);
};
