import React from "react";

export default function CompletionCircle({ percentage }) {
  const circleStyle = {
    strokeDasharray: `${percentage}, 100`,
  };

  return (
    <div className="completion_indicator" data-percentage={percentage}>
      <svg viewBox="0 0 36 36" className="circular-chart">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: "#44D7B6", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#B620E0", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke="#eee"
          strokeWidth="2.8"
        ></path>

        <path
          className="circle"
          style={circleStyle}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="2.8"
          strokeLinecap="round"
        ></path>
      </svg>
      <span className="percentage">{percentage}%</span>
    </div>
  );
}
