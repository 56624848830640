import { getApiUrl } from "../contexts/apiContext";
const api_url = getApiUrl();

async function fetchStep(user, endpoint, method = "GET", body = null) {
  try {
    const tk = await user.token;
    const options = {
      method,
      headers: {
        Authorization: "Bearer " + tk,
        "Content-Type": "application/json",
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }
    const response = await fetch(`${api_url}/step/${endpoint}`, options);

    switch (response.status) {
      case 200:
      case 201:
        return await response.json();
      case 400:
        throw new Error("Erreur de validation");
      case 401:
        return (window.location.href = "/login-register?tk=true");
      case 404:
        throw new Error("Ressource non trouvée");
      default:
        throw new Error("Erreur inconnue");
    }
  } catch (error) {
    console.error("Erreur :", error);
    throw error;
  }
}

// Récupérer toutes les steps
export async function getAllSteps(user) {
  return fetchStep(user, "");
}

// Récupérer une step par ID
export async function getStepById(user, stepId) {
  return fetchStep(user, `${stepId}`);
}

// Récupérer les steps d'un utilisateur
export async function getStepsFromUserId(user) {
  return fetchStep(user, `${user._id}`);
}

// Récupérer les steps d'un événement
export async function getStepsFromEventId(user, eventId) {
  return fetchStep(user, `event/${eventId}`);
}

// Créer une nouvelle step
export async function createStep(user, generalStepsContent, stepsData) {
  const body = { generalStepsContent, stepsData };
  return fetchStep(user, "create", "POST", body);
}

// Mettre à jour une step
export async function updateStep(user, generalStepsContent, stepsData) {
  const body = { generalStepsContent, stepsData };

  return fetchStep(user, `update/${generalStepsContent._id}`, "PATCH", body);
}

// Supprimer une step
export async function deleteStep(user, step) {
  return fetchStep(user, "delete", "DELETE", step);
}

// Check a quelles steps l'user à accès
export async function validateStepAccess(user, eventId, discord_server_id) {
  const body = { eventId, discord_server_id };
  return fetchStep(user, `check/${user._id}`, "POST", body);
}
